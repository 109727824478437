// import path from 'path'
import axios from 'axios'

function truncate (d, p) {
  return new Intl.NumberFormat('ES-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: p,
    maximumFractionDigits: p
  }).format(d)
}
function obtenerNum (item, index) {
  const regimen = {
    index: index + 1,
    num: item.trim().substring(0, 3),
    nombre: item.replace('-', '').substring(3).trimStart()
  }
  return regimen
}

async function validarFecha (fecha, servicio, distribuidor) {
  const fechac = new Date(fecha)
  const fechahoy = new Date()
  const diferenciaEnTiempo = fechahoy.getTime() - fechac.getTime()

  // Convertir la diferencia de milisegundos a días
  const diferenciaEnDias = Math.floor(
    diferenciaEnTiempo / (1000 * 60 * 60 * 24)
  )

  if (fechac > fechahoy) {
    return 'La fecha de compra debe de ser menor o igual a la fecha actual'
  }

  if (servicio === 'GARANTIA' && diferenciaEnDias >= 365) {
    return 'La fecha de compra del artículo excede el tiempo de garantía. (1 año)'
  } else if (servicio === 'GARANTIA' && diferenciaEnDias <= 365) {
    return true
  }

  if (servicio === 'ACCESORIO' && diferenciaEnDias >= 31) {
    return 'La fecha de compra del artículo excede el tiempo de limite para accesorios faltantes. (1 mes)'
  } else if (servicio === 'ACCESORIO' && diferenciaEnDias <= 31) {
    return true
  }

  if (servicio === 'INSTALACION' && diferenciaEnDias >= 90) {
    return 'La fecha de compra del articulo no debe de exceder los 90 dias'
  }

  const distArr = distribuidor.split('-')

  const datadis = { date: fecha, distributor_id: distArr[0] }
  const response = await axios
    .post(process.env.REACT_APP_BACKEND_URL + 'Productos/Ofertas', datadis)
    .then((response) => {
      if (response.data.status === true) {
        return true
      } else {
        return response.data.msg
      }
    })
    .catch((error) => {
      return 'Favor de intentarlo mas tarde' + error
    })

  return response
}

async function llenarForm (datos, imagen) {
  const form = new FormData()
  form.append('esDistribuidor', datos.esDistribuidor)
  form.append('nombre', datos.nombre)
  form.append('apellidos', datos.apellidos)
  form.append('cp', datos.cp)
  form.append('estado', datos.estado)
  form.append('ciudad', datos.ciudad)
  form.append('colonia', datos.colonia)
  form.append('calle', datos.calle)
  form.append('numExterior', datos.numExterior)
  form.append('numInterior', datos.numInterior)
  form.append('entreCalles', datos.entreCalles)
  form.append('telefono', datos.telefono)
  form.append('telefonoOpc', datos.telefonoOpc)
  form.append('email', datos.email)
  form.append('familia', datos.familia)
  form.append('modelo', datos.modelo)
  form.append('numSerie', datos.numSerie)
  form.append('cantidad', datos.cantidad)
  form.append('servicio', datos.servicio)
  form.append('comentario', datos.comentario)
  form.append('distribuidor', datos.distribuidor)
  form.append('nombreDistribuidor', datos.nombreDistribuidor)
  form.append('fechaCompra', datos.fechaCompra)
  form.append('folioCompra', datos.folioCompra)
  form.append('ticket', imagen)

  return form
}

function validarNombres (nombre) {
  const regexNombre = /^[A-Za-zÁÉÍÓÚÑáéíóúñ\s'-]{2,50}$/
  return regexNombre.test(nombre)
}

function validarEntreCalles (campoEntreCalles) {
  const regexEntreCalles = /^[a-zA-Z0-9\s\-.'/ñ]+$/
  return regexEntreCalles.test(campoEntreCalles)
}

function validarTelefono (telefono) {
  const regexPhone = /^\d{10}$/
  return regexPhone.test(telefono)
}

function validarEmail (email) {
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regexEmail.test(email)
}

function validarModelo (modelo) {
  const regexModelo = /^[A-Za-z0-9]{1,20}$/
  return regexModelo.test(modelo)
}

function validarSerie (serie) {
  const regexSerie = /^[A-Za-z0-9-]{1,20}$/
  return regexSerie.test(serie)
}

function validarFolioPasito (folioCompra) {
  const regex = /^\d{9,12}$/
  return regex.test(folioCompra)
}

function validarEmpresa (empresa) {
  const regexEmpresa = /^[A-Za-z0-9\s.,'()-]+$/
  return regexEmpresa.test(empresa)
}

export {
  truncate,
  obtenerNum,
  validarFecha,
  llenarForm,
  validarNombres,
  validarEntreCalles,
  validarTelefono,
  validarEmail,
  validarModelo,
  validarSerie,
  validarEmpresa,
  validarFolioPasito
}
