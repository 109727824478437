import { useState } from 'react'
import axios from 'axios'

export const useFormContacto = (initialForm, validateForm) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const handleChange = (event) => {
    const { name, value } = event.target
    setForm({
      ...form,
      [name]: value
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const err = validateForm(form)

    if (err === null) {
      setLoading(true)
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Contacto/', form)
        .then(function (res) {
          res = res.data

          setResponse(res)

          setTimeout(() => setLoading(false), 0)
        })
        .catch(function () {
          const res = {
            status: false,
            msg: 'Favor de intentarlo mas tarde'
          }
          setResponse(res)
          setTimeout(() => setResponse(null), 3000)
          setTimeout(() => setLoading(false), 0)
        })
    } else {
      setErrors(validateForm(form))
      setTimeout(() => setErrors({}), 3000)
    }
  }

  return {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  }
}
