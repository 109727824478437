import React from 'react'
import './MostrarPreOrden.css'
import PropTypes from 'prop-types'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MostrarPreOrdenPDF from './MostrarPreOrdenPDF'

const MostrarPreOrden = ({ orden }) => {
  return (
    <>
      <div>
        <div>
          <p>
            <b className='negritas'>Orden(es) de atención: </b>
            {orden.id}
          </p>
          <p>
            <b className='negritas'>Fecha: </b>
            {orden.fecha}
          </p>
          <p>
            <b className='negritas'>Distribuidor: </b>
            {orden.distribuidor}
          </p>
          <br />
          <h4 className='title-datos'>Datos del cliente</h4>
          <p>
            <b className='negritas'>Nombre: </b>
            {orden.nombre}
          </p>
          <p>
            <b className='negritas'>Dirección: </b>
            {orden.direccion}
          </p>
          <p>
            <b className='negritas'>Colonia: </b>
            {orden.colonia}
          </p>
          <p>
            <b className='negritas'>Ciudad: </b>
            {orden.ciudad}
          </p>
          <br />
          <table className='table-info'>
            <tbody className='table-body'>
              <tr className='renglon-modal'>
                <th className='columnas-modal'>Comentario</th>
                <th className='columnas-modal'>Servicio</th>
              </tr>
              <tr className='renglon-modal'>
                <td className='valor-modal'> {orden.comentario} </td>
                <td className='valor-modal'> {orden.tipo} </td>
              </tr>
            </tbody>
          </table>
          <h6 className='indicaciones'>
            * Recuerda que es importante que tengas tu ticket cuando el técnico
            haga la visita a tu domicilio.
          </h6>
          <h6 className='indicaciones'>
            * El técnico se comunicará con usted en los siguientes días para
            agendar la visita.
          </h6>
          <h6 className='indicaciones'>
            * Guarda tu orden para futuras aclaraciones.
          </h6>
          <h6 className='indicaciones'>
            * Se aplican restricciones
          </h6>
        </div>
        <PDFDownloadLink
          document={<MostrarPreOrdenPDF orden={orden} />}
          fileName={`pre-orden-${orden.id}.pdf`}
        >
          <button className='buscador-button button-center'>
            Descargar PDF
          </button>
        </PDFDownloadLink>
      </div>
    </>
  )
}

MostrarPreOrden.propTypes = {
  orden: PropTypes.object.isRequired
}

export default MostrarPreOrden
