import axios from 'axios'
import { useEffect, useState } from 'react'
import { obtenerNum } from '../../modules/Functions'

export const useForm = (initialForm, validateForm, orden) => {
  const [regimenF, setRegimenF] = useState([])
  const [cfdiF, setCfdiF] = useState([])
  const [col, setCol] = useState([])
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + 'Regimen/', {}, {
        headers: {
          Authorization: `Bearer ${orden.token}`
        }
      })
      .then(function (response) {
        const regi = response.data
        const separado = regi.map(obtenerNum)
        setRegimenF(separado)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'rfc') {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Cliente/', { rfc: value }, {
          headers: {
            Authorization: `Bearer ${orden.token}`
          }
        })
        .then(function (response) {
          response = response.data
          const cliente = response.data
          if (cliente !== null && cliente !== undefined) {
            form.nombre = cliente.name
            form.rfc = cliente.rfc
            setForm(form)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    if (name === 'cp' && value.length >= 5) {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Colonias/', { cp: value }, {
          headers: {
            Authorization: `Bearer ${orden.token}`
          }
        })
        .then(function (response) {
          const dir = response.data
          if (dir.status) {
            const { data } = dir

            form.cp = data.cp.toString()
            form.estado = data.estado.toString()
            form.ciudad = data.ciudad.toString()
            form.colonia = ''
            setCol(data.colonias)
            setForm(form)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    if (name === 'regimen' && value !== '') {
      const regimen = value.substring(0, 3)
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'uso/', { fiscal_regime: regimen }, {
          headers: {
            Authorization: `Bearer ${orden.token}`
          }
        })
        .then(function (response) {
          const usoi = response.data
          const separado = usoi.map(obtenerNum)
          setCfdiF(separado)
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    setForm({
      ...form,
      [name]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    const err = validateForm(form)

    if (err === null) {
      if (form.MP !== '') {
        orden.metodo_pago = form.MP
      }
      const datosOrden = {
        nombre: form.nombre,
        rfc: form.rfc,
        cp: form.cp,
        estado: form.estado,
        ciudad: form.ciudad,
        colonia: form.colonia,
        regimen: form.regimen,
        cfdi: form.cfdi,
        orden
      }
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Facturar/', datosOrden, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${orden.token}`
          }
        })
        .then(function (response) {
          if (response.data.status === false) {
            response.data.error = 1
            setResponse(response.data)
          } else {
            response.data.error = 0
            setResponse(response.data)
          }

          setLoading(false)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setErrors(validateForm(form))
      setLoading(false)
      setTimeout(() => setErrors({}), 3000)
    }
  }

  return {
    regimenF,
    cfdiF,
    col,
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  }
}
