import React from 'react'
import './jumbotron.css'
import PropTypes from 'prop-types'

function Jumbotron (props) {
  return (
    <div className="jumbotron" style={{ backgroundImage: `url(${props.image})` }}>
      <div className="container">
        <div className="col-group">
          <div className="col">
            <div className="col-content">
              <div className="module">
                <div className="module-content">
                  <div className="module-content-content">
                    <div className="info">
                      <div className="info-content">
                        <div className='title-container'>
                          <h1 className="display-4">{props.title}</h1>
                        </div>
                        <hr className="my-4" />
                        <div className="description-container">
                          <p className="lead">{props.description}</p>
                        </div>

                        <a href={props.url} className='button-marcas'>{props.additionalText}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Jumbotron.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  url: PropTypes.string.isRequired
}

export default Jumbotron
