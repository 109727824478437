import React from 'react'
import './jumbotron2.css'
import PropTypes from 'prop-types'

function Jumbotronpages (props) {
  return (
    <div className='row-jumbotron'>
      <div
        className='row-content-wrap-jumbotron'
        style={{ backgroundImage: `url(${props.image})`, height: props.alto }}
      >
        <div className='row-content-jumbotron'>
          <div className='col-group-jumbotron'>
            <div className='col-jumbotron'>
              <div className='col-content-jumbotron'>
                <div className='module-jumbotron'>
                  <div className='module-content-jumbotron'>
                    <div className='heading-content-jumbotron'>
                      <div className='heading-jumbotron'>
                        <h2 className='heading-title-jumbotron' style={{ marginTop: props.margin }}>
                          <span className='title-text-jumbotron'>
                            {props.title}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Jumbotronpages.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alto: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired
}

export default Jumbotronpages
