import React, { useEffect } from 'react'
import './Inicio.css'
import Jumbotron from '../../helpers/Jumbotron/jumbotron'
// import { Footer } from '../../helpers/Footer/Footer'

function Inicio (props) {
  useEffect(() => {
    document.title =
      'Industrias Macon | Expertos en Confort Ambiental – Expertos en confort ambiental' || ' '
  }, [])
  return (
    <div>
      <div>
        <Jumbotron
          image="./img/side1.jpg"
          title="Expertos en Confort Ambiental"
          description="En Industrias Macon entendemos que nuestra misión es hacer que la vida de
          las personas sea más confortable, ofreciendo soluciones efectivas para climatizar los
          espacios donde viven su vida diariamente."
          additionalText="CONOCE NUESTRAS MARCAS"
          url='/marcas'
        />
      </div>
      <div className='row'>
        <div className='row-content-wrap'>
          <div className='row-content'>
            <div className='col-group-inicio'>
              <div className='col-inicio'>
                <div className='col-content-inicio'>
                  <div className='module-inicio'>
                    <div className='module-content-inicio'>
                      <div className='heading-content-inicio'>
                        <div className='heading-title-inicio'>
                          <h3 className='heading-title'>
                            <span className='title-text-inicio'>Nuestras Marcas</span>
                          </h3>
                        </div>
                        <div className='separador-inicio'>
                          <span className="linea-sep"></span>
                        </div>
                        <div className='heading-description'>
                          <p className='description-inicio'>Industrias Macon cuenta con marcas de enfriadores de aire
                            acondicionado, aire evaporativo, calefactores y boilers, que
                            son reconocidas en el mercado por su calidad y confiabilidad.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-inicio'>
                <div className='col-content-inicio'>
                  <div className='module-inicio'>
                    <div className='module-content-inicio-logos'>
                      <div className='logos-container'>
                        <div className='logos-wrapper'>
                          <div className="logo">
                            <a href="https://www.frikko.com/" className='link-logo'>
                              <div className="logo-inner">
                                <div className="logo-inner-wrap">
                                  <div className="image-wrapper">
                                    <img decoding='async' src='./img/Logo-frikko-blanco.png' alt='' className='logo-image' />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="logo">
                            <a href="https://www.heatwave.com.mx/" className='link-logo'>
                              <div className="logo-inner">
                                <div className="logo-inner-wrap">
                                  <div className="image-wrapper">
                                    <img decoding='async' src="./img/Logo-heatwave-blanco.png" alt="" className="logo-image" />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="logo">
                            <a href="https://www.frikko.com/" className='link-logo'>
                              <div className="logo-inner">
                                <div className="logo-inner-wrap">
                                  <div className="image-wrapper">
                                    <img decoding='async' src="./img/Logo-maxxicool-blanco.png" alt="" className="logo-image" />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="logo">
                            <a href="https://www.frikko.com/" className='link-logo'>
                              <div className="logo-inner">
                                <div className="logo-inner-wrap">
                                  <div className="image-wrapper">
                                    <img decoding='async' src="./img/Logo-airon-blanco.png" alt="" className="logo-image" />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inicio
