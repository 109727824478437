import { truncate } from '../../../modules/Functions'
import React from 'react'
import PropTypes from 'prop-types'

export const TablaRow = (props) => {
  const pro = props.props
  return (
        <tr>
            <td>{pro.ReferenciaSAT}</td>
            <td>{pro.UnidadSAT}</td>
            <td>{pro.sku}</td>
            <td>{pro.description}</td>
            <td>{pro.cantidad}</td>
            <td className="cost">{truncate(pro.IVA, 2)}</td>
            <td className="cost">{truncate(pro.precioUnitario, 2)}</td>
            <td className="cost">{truncate(pro.precioTotal, 2)}</td>
        </tr>
  )
}

TablaRow.propTypes = {
  props: PropTypes.object.isRequired
}
