import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

import Dropdown from '../Dropdown/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
  const [click, setClick] = useState(false)
  const [dropdown, setDropdown] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(true)
    } else {
      setDropdown(true)
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false)
    } else {
      setDropdown(false)
    }
  }

  return (
    <>
      <header className='site-header'>
        <nav className='navbar'>
          <Link
            to='/'
            onClick={closeMobileMenu}
            className='enlace'
          >
            <img
              srcSet='./img/logo.png'
              alt=''
            />
          </Link>
          <div
            className='menu-icon'
            onClick={handleClick}
          >
            <FontAwesomeIcon
              color='#000'
              icon={click ? faXmark : faBars}
            />
          </div>
          <ul className={`nav-menu ${click ? 'active' : ''}`}>
            <li className='nav-item'>
              <Link
                to='/sobre-nosotros'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Nosotros
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/marcas'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Marcas
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contacto'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contacto
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/facturacion'
                className='nav-links'
                onClick={handleClick}
              >
                Facturacion
              </Link>
            </li>
            <li
              className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                className='nav-links'
                onClick={handleClick}
              >
                Soporte{' '}
                <FontAwesomeIcon
                  color='#000'
                  icon={faCaretDown}
                />
              </Link>
              {dropdown && <Dropdown />}
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Navbar
