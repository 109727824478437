import React, { useEffect, useState } from 'react'
import './Marcas.css'
import Jumbotronpages from '../../helpers/Jumbotron2/jumbotron2'

function Marcas () {
  const [windowSize, setWindowSize] = useState(null)

  useEffect(() => {
    document.title = 'Marcas' || ' '
    setWindowSize(window.innerWidth)
  }, [])

  const handleResize = () => {
    setWindowSize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const altoDevolver = (windowSize) => {
    if (windowSize > 992) {
      return { alto: 318, margen: 60 }
    } else if (windowSize <= 992 && windowSize >= 816) {
      return { alto: 318, margen: 100 }
    } else if (windowSize < 816 && windowSize > 768) {
      return { alto: 218, margen: 10 }
    } else if (windowSize <= 768) {
      return { alto: 76, margen: 30 }
    }
  }

  return (
    <div>
      <Jumbotronpages
        image='./img/side3.jpg'
        title='NUESTRAS MARCAS'
        alto={altoDevolver(windowSize).alto}
        margin={altoDevolver(windowSize).margen}
      />
      <div className='row-marcas-blog'>
        <div className='row-content-wrap-marcas-blog'>
          <div className='row-content-marcas-blog'>
            <div className='col-group-marcas-blog'>
              <div className='col-marcas-blog'>
                <div className='col-content-marcas-blog'>
                  <div className='module-marcas-blog'>
                    <div className='module-content-marcas-blog'>
                      <div className='blogs'>
                        <div className='blog-container-marcas'>
                          <div className='blog-posts-marcas'>
                            <div className='blog-post-marcas'>
                              <div className='post-thumbnail-marcas'>
                                <a href='https://www.frikko.com/' className='link-marcas'>
                                  <img
                                    src='./img/Logo-frikko-azul.png'
                                    alt=''
                                    className='imagen-marcas'
                                  />
                                </a>
                              </div>
                              <div className='post-content-marcas'>
                                <span className='read-more-text-marcas'>
                                  <a href='https://www.frikko.com/' className='ver-marca'>
                                    Ver Marca{' '}
                                    <span className='ver-mas-marcas'>→</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='blog-container-marcas'>
                          <div className='blog-posts-marcas'>
                            <div className='blog-post-marcas'>
                              <div className='post-thumbnail-marcas'>
                                <a href='https://www.heatwave.com.mx/' className='link-marcas'>
                                  <img
                                    src='./img/Logo-heatwave-color.png'
                                    alt=''
                                    className='imagen-marcas'
                                  />
                                </a>
                              </div>
                              <div className='post-content-marcas'>
                                <span className='read-more-text-marcas'>
                                  <a href='https://www.heatwave.com.mx/' className='ver-marca'>
                                    Ver Marca{' '}
                                    <span className='ver-mas-marcas'>→</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='blog-container-marcas'>
                          <div className='blog-posts-marcas'>
                            <div className='blog-post-marcas'>
                              <div className='post-thumbnail-marcas'>
                                <a href='https://www.frikko.com/' className='link-marcas'>
                                  <img
                                    src='./img/Logo-airon-color.png'
                                    alt=''
                                    className='imagen-marcas'
                                  />
                                </a>
                              </div>
                              <div className='post-content-marcas'>
                                <span className='read-more-text-marcas'>
                                  <a href='https://www.frikko.com/' className='ver-marca'>
                                    Ver Marca{' '}
                                    <span className='ver-mas-marcas'>→</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='blog-container-marcas'>
                          <div className='blog-posts-marcas'>
                            <div className='blog-post-marcas'>
                              <div className='post-thumbnail-marcas'>
                                <a href='https://www.frikko.com/' className='link-marcas'>
                                  <img
                                    src='./img/Logo-maxxicool-color.png'
                                    alt=''
                                    className='imagen-marcas'
                                  />
                                </a>
                              </div>
                              <div className='post-content-marcas'>
                                <span className='read-more-text-marcas'>
                                  <a href='https://www.frikko.com/' className='ver-marca'>
                                    Ver Marca{' '}
                                    <span className='ver-mas-marcas'>→</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default Marcas
