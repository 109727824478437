import { useState } from 'react'
import axios from 'axios'
import { llenarForm } from '../../modules/Functions'
import imageCompression from 'browser-image-compression'

export const useFormFoliosServicio = (
  initialForm,
  handleSuccess,
  validateForm,
  infoForm
) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [info, setInfo] = useState({})
  const [col, setCol] = useState(null)
  const [categorias, setCategorias] = useState(null)
  const [productos, setProductos] = useState(null)
  const [loading, setLoading] = useState(false)
  const [imagen, setImagen] = useState(null)
  const [response, setResponse] = useState(null)

  const handleChange = async (event) => {
    const { name, checked, value, files } = event.target
    setForm({
      ...form,
      [name]: value
    })

    // if (name === 'pagina') {
    //   // Limpiar combo
    //   setMarca(value)
    //   setTipo(null)
    //   setProductos(null)
    //   setVariaciones(null)
    //   form.marca = marca
    //   form.tipoProducto = ''
    //   form.producto = ''
    //   form.modelo = ''
    //   setForm(form)
    //   axios
    //     .post(process.env.REACT_APP_BACKEND_URL + 'Productos/TipoProducto/', {
    //       marca: value
    //     })
    //     .then(function (response) {
    //       const dir = response.data
    //       if (dir.status === true) {
    //         setTipo(dir.data)
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // }

    if (name === 'esDist') {
      form.esDistribuidor = checked
      form.nombre = ''
      form.apellidos = ''

      if (form.distribuidor !== '' && checked === true && form.distribuidor.split('-')[1] !== 'Otro') {
        form.nombre = form.distribuidor.split('-')[1]
      }

      setForm(form)
    }

    if (name === 'distribuidor' && form.esDistribuidor === true) {
      if (value.split('-')[1] !== 'Otro') {
        form.nombre = value.split('-')[1]
      }

      setForm(form)
    } else if (name === 'distribuidor' && form.esDistribuidor === false) {
      if (form.nombre === '') {
        form.nombre = ''

        setForm(form)
      }
    }

    if (name === 'familia') {
      setCategorias(null)
      setProductos(null)
      form.categoria = ''
      form.modelo = ''
      form.producto = ''
      form.cantidad = ''
      setForm(form)

      axios
        .get(process.env.REACT_APP_BACKEND_URL + `Productos/Categorias/${value}`)
        .then(function (response) {
          const dir = response.data
          if (dir.status === true) {
            setCategorias(dir.data)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    if (name === 'categoria') {
      form.producto = ''
      form.modelo = ''
      setForm(form)

      axios
        .get(process.env.REACT_APP_BACKEND_URL + `Productos/Mostrar/${form.familia}/${value}`)
        .then(function (response) {
          const dir = response.data
          if (dir.status === true) {
            setProductos(dir.data)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    if (name === 'producto') {
      form.producto = value
      if (value !== 'OTRO') {
        form.modelo = value
      } else {
        form.modelo = ''
      }
      setForm(form)
    }

    if (name === 'telefono') {
      form.telefono = value.replace(/\D/g, '')
      setTimeout(() => { setForm(form) }, 0)
    }

    if (name === 'telefonoOpc') {
      form.telefonoOpc = value.replace(/\D/g, '')
      setTimeout(() => { setForm(form) }, 0)
    }

    if (name === 'cp') {
      const regex = /^\d*$/

      if (!regex.test(value)) {
        form.cp = ''
        form.estado = ''
        form.ciudad = ''
        form.colonia = ''
        setCol(null)
        setForm(form)
      } else {
        const regexCP = /^\d{5}$/
        if (value.length === 5) {
          if (regexCP.test(value)) {
            axios
              .post(process.env.REACT_APP_BACKEND_URL + 'Colonias/', {
                cp: value
              })
              .then(function (response) {
                const dir = response.data
                if (dir.status) {
                  const { data } = dir

                  form.cp = data.cp.toString()
                  form.estado = data.estado.toString()
                  form.ciudad = data.ciudad.toString()
                  form.colonia = ''
                  setCol(data.colonias)
                  setForm(form)
                }
              })
              .catch(function (error) {
                console.log(error)
              })
          }
        } else {
          setTimeout(() => {
            form.cp = value
            form.estado = ''
            form.ciudad = ''
            form.colonia = ''
            setCol(null)
            setForm(form)
          }, 0)
        }
      }
    }

    if (name === 'servicio') {
      form.servicio = value
      setForm(form)
      const info = await infoForm(form)

      setInfo(info)
    }

    if (name === 'imagen') {
      const { name } = files[0]
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 700
      }
      try {
        const compressedFile = await imageCompression(files[0], options)
        const image = new File([compressedFile], name, {
          type: compressedFile.type
        })
        setImagen(image)
      } catch (error) {
        console.log(error)
      }
    }

    if (name === 'nombreDistribuidor' && form.esDistribuidor === true) {
      form.nombre = value
      setForm(form)
    }

    setForm({
      ...form,
      [name]: value
    })
  }

  const captchaChange = (event) => {
    if (event) {
      setTimeout(() => {
        form.captcha = true
        setForm(form)
      }, 0)
    } else {
      setTimeout(() => {
        form.captcha = false
        setForm(form)
      }, 0)
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const err = await validateForm(form)

    if (err === null) {
      const enviar = await llenarForm(form, imagen)
      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Servicios', enviar, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          if (response.status === false) {
            setLoading(false)
          }
          setResponse(response.data)
          handleSuccess()
        })
        .catch(function (response) {
          console.log(response)
          setLoading(false)
        })
    } else {
      setErrors(err)
      setLoading(false)
      setTimeout(() => setErrors({}), 3000)
    }
  }

  return {
    form,
    errors,
    info,
    loading,
    response,
    col,
    categorias,
    productos,
    captchaChange,
    handleChange,
    handleSubmit
  }
}
