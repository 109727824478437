import React from 'react'
import './Modal.css'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export const Modal = ({ ancho, header, children, isOpen, closeModal }) => {
  const clickContainer = (e) => e.stopPropagation()
  return (
    <div className={`modal ${isOpen && 'is-open'}`} >
      <div className="modal-content" onClick={clickContainer} style={{ maxWidth: ancho + 'px' }}>
        <div className="modal-header">
          <h2 className='header-modal'>{header}</h2>
          <span>
            <i onClick={closeModal}>
              <FontAwesomeIcon color='#fff' className='cerrar' icon={faXmark}/>
            </i>
          </span>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  ancho: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
}
