import React, { useEffect } from 'react'
import './MostrarOrden.css'
import { Modal } from '../../helpers/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import DatosCliente from './DatosCliente'
import { useLocation, useNavigate } from 'react-router-dom'
import { TablaRow } from '../../helpers/TableRow/TableRow'
import { truncate } from '../../../modules/Functions'

const MostrarOrden = () => {
  const [isOpenModal, openModal, closeModal] = useModal(false)
  let { state } = useLocation()
  const navigate = useNavigate()

  const orden = state?.orden
  const datos = state?.response
  const datosO = datos?.data

  useEffect(() => {
    if (!orden || !datos || !datosO) {
      navigate('/facturacion')
    }
  }, [navigate, orden, datos, datosO])

  if (datosO) {
    datosO.id = orden
    return (
      <>
        <div className="contenedor-padre">
          <div className="card">
            <div className='title-container'>
              <h2 className='title'>NUMERO DE ORDEN: <span>{orden}</span></h2>
            </div>
            <div className='table-container'>
              <table className='tabla'>
                <thead>
                  <tr>
                    <th>REFERENCIA SAT</th>
                    <th>UNIDAD SAT</th>
                    <th>SKU</th>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>IVA</th>
                    <th>Precio Unitario</th>
                    <th>Precio Total</th>
                  </tr>
                </thead>
                <tbody>
                  {datosO.items.map((item) =>
                    <TablaRow key={item.id} props={item} />
                  )}
                  <tr>
                    <td className='fixed' colSpan={7}>Subtotal</td>
                    <td colSpan={1} className="cost">{truncate(datosO.subtotal, 2)}</td>
                  </tr>
                  <tr>
                    <td className='fixed' colSpan={7}>IVA</td>
                    <td colSpan={1} className="cost">{truncate(datosO.IVA, 2)}</td>
                  </tr>
                  <tr>
                    <td className='fixed' colSpan={7}>Total</td>
                    <td colSpan={1} className="cost">{truncate(datosO.total, 2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='container-facturar'>
              <button className='facturar' onClick={openModal}>Ingresar datos de facturación</button>
            </div>
          </div>
        </div>
        <Modal ancho={600} header="Datos de Facturacion" isOpen={isOpenModal} closeModal={closeModal}>
          <DatosCliente orden={datosO} />
        </Modal>
      </>)
  }
  state = null
}

export default MostrarOrden
