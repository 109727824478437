import React, { useEffect } from 'react'
import Jumbotronpages from '../../helpers/Jumbotron2/jumbotron2'
import { useFormContacto } from '../../hooks/useFormContacto'
import './Contacto.css'
import Spinner from '../../helpers/spinner/spinner'
import swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'

const initialForm = {
  nombre: '',
  email: '',
  telefono: '',
  mensaje: ''
}

const validationsForm = (form) => {
  const errors = {}
  let iserror = false
  const regexNOMBRE = /^[a-zA-ZñÑ\s]+$/
  const regexPhone = /^[0-9]{1,10}$/
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  if (!form.nombre.trim()) {
    errors.nombre = "El campo 'Nombre' es requerido"
    iserror = true
  } else if (!regexNOMBRE.test(form.nombre)) {
    errors.nombre = "El campo 'Nombre' solo acepta letras y espacios"
    iserror = true
  }

  if (!form.email.trim()) {
    errors.email = "El campo 'Email' es requerido"
    iserror = true
  } else if (!regexEmail.test(form.email)) {
    errors.email = "El campo 'Email' solo acepta letras y espacios"
    iserror = true
  }

  if (!form.telefono.trim()) {
    errors.telefono = "El campo 'Telefono' es requerido"
    iserror = true
  } else if (!regexPhone.test(form.telefono)) {
    errors.telefono = "El campo 'Telefono' solo acepta letras y espacios"
    iserror = true
  }

  if (!form.mensaje.trim()) {
    errors.mensaje = "El campo 'Mensaje' es requerido"
    iserror = true
  }

  return iserror ? errors : null
}
const mostrarAlerta = (response) => {
  if (response.status === false) {
    swal
      .fire({
        title: 'Se ha presentado un error',
        text: response.msg,
        icon: 'error',
        confirmButtonText: 'OK',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/Contacto'
        }
      })
  } else if (response.status === true) {
    swal
      .fire({
        title: 'Se ha enviado el correo correctamente',
        text: response.msg,
        icon: 'success',
        confirmButtonText: 'OK',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/Contacto'
        }
      })
  }
}

function Contacto () {
  useEffect(() => {
    document.title = 'Contacto' || ' '
  }, [])

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  } = useFormContacto(initialForm, validationsForm)

  return (
    <div>
      <Jumbotronpages
        image='./img/side4.jpg'
        title='CONTACTO'
        alto={358}
        margin={80}
      />
      <div className='row-contacto row-color'>
        <div className='row-content-wrap-contacto-color'>
          <div className='row-content-contacto-color'>
            <div className='col-group-contacto-color'>
              <div className='col-contacto-color'>
                <div className='col-content-contacto-color'>
                  <div className='module-contacto-color'>
                    <div className='module-content-contacto-color'>
                      <div className='rich-text-color'>
                        <p className='texto-contacto-color'>
                          <strong className='text-color-strong'>
                            Industrias Macon no solicita contribuciones para
                            participar en sus procesos de reclutamiento y
                            selección, o bien, para laborar en la empresa.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row-contacto'>
        <div className='row-content-wrap-form'>
          <div className='row-content-form'>
            <div className='col-group-contacto-form'>
              <div className='col-contacto-form'>
                <div className='col-content-mapa'>
                  <div className='module-mapa'>
                    <div className='module-content-mapa'>
                      <div className='heading-content-mapa'>
                        <div className='heading-mapa'>
                          <h3 className='heading-title-mapa'>
                            <span className='title-text-span-header'>
                              Encuéntranos en el mapa
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='module-iframe'>
                    <div className='module-content-iframe'>
                      <div className='map-iframe'>
                        <iframe
                          className='iframe-macon'
                          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3599.391896821231!2d-103.45967
                        08!3d25.5586254!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fd9825080454d%3A0x6e1e3c4d9187f89!2sIndus
                        trias%20Macon!5e0!3m2!1sen!2sus!4v1685469903754!5m2!1sen!2sus'
                          width='600'
                          height='450'
                          style={{ pointerEvents: 'auto', border: 0 }}
                          allowFullScreen=''
                          loading='lazy'
                          referrerPolicy='no-referrer-when-downgrade'
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-contacto-form'>
                <div className='col-content-form'>
                  <div className='module-header'>
                    <div className='module-content-header'>
                      <div className='heading-contant-header'>
                        <div className='heading-header'>
                          <h3 className='heading-title-header'>
                            <span className='title-text-span-header'>
                              Ponte en contacto
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='module-contacto-form'>
                    <div className='module-content-form'>
                      <form
                        className='form-contacto'
                        onSubmit={handleSubmit}
                      >
                        <label
                          className='buscador-label'
                          htmlFor='Nombre'
                        >
                          Nombre:
                        </label>
                        <input
                          type='text'
                          name='nombre'
                          id='nombre'
                          className='buscador-input'
                          placeholder='Nombre'
                          onChange={handleChange}
                          value={form.nombre}
                        />
                        {errors.nombre && errors.nombre !== ''
                          ? (<p className='msg-error'>{errors.nombre}</p>)
                          : (<p><br /></p>)}
                        <label
                          className='buscador-label'
                          htmlFor='Nombre'
                        >
                          Correo Electronico:
                        </label>
                        <input
                          type='text'
                          name='email'
                          id='email'
                          className='buscador-input'
                          placeholder='Correo Electronico'
                          onChange={handleChange}
                          value={form.email}
                        />
                        {errors.email && errors.email !== ''
                          ? (<p className='msg-error'>{errors.email}</p>)
                          : (<p><br /></p>)}
                        <label
                          className='buscador-label'
                          htmlFor='Telefono'
                        >
                          Telefono:
                        </label>
                        <input
                          type='text'
                          name='telefono'
                          id='telefono'
                          className='buscador-input'
                          placeholder='Telefono'
                          onChange={handleChange}
                          value={form.telefono}
                        />
                        {errors.telefono && errors.telefono !== ''
                          ? (<p className='msg-error'>{errors.telefono}</p>)
                          : (<p><br /></p>)}
                        <label
                          className='buscador-label'
                          htmlFor='Mensaje'
                        >
                          Mensaje:
                        </label>
                        <textarea
                          type='text'
                          name='mensaje'
                          id='mensaje'
                          className='buscador-input text-area'
                          placeholder='Mensaje'
                          onChange={handleChange}
                          value={form.mensaje}
                        />
                        {errors.mensaje && errors.mensaje !== ''
                          ? (<p className='msg-error'>{errors.mensaje}</p>)
                          : (<p><br /></p>)}
                        <div className='container-button'>
                          {loading === true && <Spinner />}
                          <button
                            className='buscador-button contacto-button'
                            type='submit'
                            disabled={loading && true}
                          >
                            Enviar
                          </button>
                        </div>
                        {response && mostrarAlerta(response)}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row-contacto row-full-width'>
        <div className='row-content-wrap-separador'>
          <div className='row-content-separador'>
            <div className='col-group-separador'>
              <div className='col-separador'>
                <div className='col-content-separador'>
                  <div className='module-separador'>
                    <div className='module-content-separador'>
                      <div className='content-separador'>
                        <div className='separador-contacto'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row-contacto'>
        <div className='row-content-wrap-ubicacion'>
          <div className='row-content-contacto-ubicacion'>
            <div className='col-group-contacto-ubicacion'>
              <div className='col-contacto-ubicacion'>
                <div className='col-content-contacto-ubicacion'>
                  <div className='module-contacto-ubicacion'>
                    <div className='module-content-contacto-ubicacion'>
                      <div className='infobox-wrap'>
                        <div className='infobox'>
                          <div className='icon-wrapper'>
                            <div className='infobox-icon'>
                              <div className='infobox-icon-inner'>
                              <FontAwesomeIcon className='icon-span' icon={faLocationDot} />
                              </div>
                            </div>
                          </div>
                          <span className='infobox-title-prefix'></span>
                          <div className='infobox-title-wrapper'>
                            <h4 className='infobox-title'>DIRECCIÓN</h4>
                          </div>
                          <div className='infobox-description'>
                            <div className='description-wrap'>
                              <p className='p1'>
                                <span className='s1'>
                                  Canatlán 370-01 Parque Industrial Lagunero
                                </span>
                                <span className='s1'>
                                  , Gómez Palacio Durango, México.&nbsp;35078.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-contacto-ubicacion'>
                <div className='col-content-contacto-ubicacion'>
                  <div className='module-contacto-ubicacion'>
                    <div className='module-content-contacto-ubicacion'>
                      <div className='infobox-wrap'>
                        <div className='infobox'>
                          <div className='icon-wrapper'>
                            <div className='infobox-icon'>
                              <div className='infobox-icon-inner'>
                              <FontAwesomeIcon className='icon-span' icon={faEnvelope} />
                              </div>
                            </div>
                          </div>
                          <span className='infobox-title-prefix'></span>
                          <div className='infobox-title-wrapper'>
                            <h4 className='infobox-title'>EMAIL</h4>
                          </div>
                          <div className='infobox-description'>
                            <div className='description-wrap'>
                              <p className='p1'>
                                <span className='s1'>
                                  informacion@imacon.com
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacto
