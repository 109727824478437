import React from 'react'
import PropTypes from 'prop-types'

export const TablaRowSeguimiento = ({ fecha, comentario }) => {
  return (
    <tr>
      <td>{fecha}</td>
      <td>{comentario}</td>
    </tr>
  )
}

TablaRowSeguimiento.propTypes = {
  comentario: PropTypes.string.isRequired,
  fecha: PropTypes.string.isRequired
}
