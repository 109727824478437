import React from 'react'
import './DatosCliente.css'
import { useForm } from '../../hooks/useFormDatos'
import Spinner from '../../helpers/spinner/spinner'
import swal from 'sweetalert2'
// import { Navigate } from 'react-router-dom'

const initialForm = {
  nombre: '',
  rfc: '',
  cp: '',
  estado: '',
  ciudad: '',
  colonia: '',
  regimen: '',
  cfdi: '',
  MP: ''
}

const mostrarAlerta = (response, onOK) => {
  if (response.error === 1) {
    swal
      .fire({
        title: 'Se ha presentado un error',
        text: response.msg,
        icon: 'error',
        confirmButtonText: 'OK',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/Facturacion'
        }
      })
  } else if (response.error === 0) {
    swal
      .fire({
        title: 'Se ha generado la factura de la orden',
        text: response.msg,
        icon: 'success',
        confirmButtonText: 'OK',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/Facturacion'
        }
      })
  }
}

const validationsForm = (form) => {
  const errors = {}
  let iserror = false
  const regexCP = /[0-9]{5}/
  const regexRFC =
    /[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/
  const regexNOMBRE = /[^|]{1,254}/

  if (!form.rfc.trim()) {
    errors.rfc = "El campo 'RFC' es requerido"
    iserror = true
  } else if (!regexRFC.test(form.rfc)) {
    errors.rfc = "El campo 'RFC' es incorrecto"
    iserror = true
  }

  if (!form.nombre.trim()) {
    errors.nombre = "El campo 'Nombre' es requerido"
    iserror = true
  } else if (!regexNOMBRE.test(form.nombre)) {
    errors.nombre = "El campo 'Nombre' solo acepta letras y espacios"
    iserror = true
  }

  if (!form.cp.trim()) {
    errors.cp = "El campo 'Código Postal' es requerido"
    iserror = true
  } else if (!regexCP.test(form.cp)) {
    errors.cp = "El campo 'Código Postal' es incorrecto"
    iserror = true
  }

  if (!form.estado.trim()) {
    errors.estado = "El campo 'Estado' es requerido"
    iserror = true
  }

  if (!form.ciudad.trim()) {
    errors.ciudad = "El campo 'Ciudad' es requerido"
    iserror = true
  }

  if (!form.colonia.trim()) {
    errors.colonia = "El campo 'Colonia' es requerido"
    iserror = true
  }

  if (!form.regimen.trim()) {
    errors.regimen = "El campo 'Regimen Fiscal' es requerido"
    iserror = true
  }

  if (!form.cfdi.trim()) {
    errors.cfdi = "El campo 'Uso del CFDI' es requerido"
    iserror = true
  }

  return iserror ? errors : null
}

const DatosCliente = (orden) => {
  const nuevaOrden = orden.orden
  const mp = nuevaOrden.metodo_pago
  const {
    regimenF,
    cfdiF,
    col,
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  } = useForm(initialForm, validationsForm, nuevaOrden)
  return (
    <>
      <form onSubmit={handleSubmit}>
        <label className='buscador-label' htmlFor='RFC'>
          RFC:
        </label>
        <input
          type='text'
          name='rfc'
          id='rfc'
          className='buscador-input'
          placeholder='Ej. MELM8305281HO O MELM8305281H'
          onChange={handleChange}
          value={form.rfc}
        />
        {errors.rfc && <p className='msg-error'>{errors.rfc}</p>}

        <label className='buscador-label' htmlFor='Nombre'>
          Nombre / Razón social
        </label>
        <input
          type='text'
          name='nombre'
          id='nombre'
          className='buscador-input'
          placeholder='Ej. Compañía'
          onChange={handleChange}
          value={form.nombre}
        />
        {errors.nombre && <p className='msg-error'>{errors.nombre}</p>}

        <label className='buscador-label' htmlFor='email'>
          Código Postal:
        </label>

        <input
          type='text'
          name='cp'
          id='cp'
          className='buscador-input'
          onChange={handleChange}
          value={form.cp}
          placeholder='Ej. 40732'
        />
        {errors.cp && <p className='msg-error'>{errors.cp}</p>}

        <label className='buscador-label' htmlFor='Estado'>
          Estado:
        </label>
        <input
          type='text'
          name='estado'
          id='estado'
          disabled
          placeholder=''
          className='buscador-input'
          onChange={handleChange}
          value={form.estado}
        />
        {errors.estado && <p className='msg-error'>{errors.estado}</p>}

        <label className='buscador-label' htmlFor='Ciudad'>
          Ciudad:
        </label>
        <input
          type='text'
          name='ciudad'
          id='ciudad'
          className='buscador-input'
          disabled
          onChange={handleChange}
          value={form.ciudad}
        />
        {errors.ciudad && <p className='msg-error'>{errors.ciudad}</p>}

        <label className='buscador-label' htmlFor='Colonia'>
          Colonia:
        </label>
        <select
          name='colonia'
          id='colonia'
          className='buscador-input'
          onChange={handleChange}
          defaultValue={''}
          disabled={form.cp.length <= 4}
        >
          <option key={0} value='' disabled>
            Selecciona la colonia
          </option>
          {col &&
            col.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
        </select>
        {errors.colonia && <p className='msg-error'>{errors.colonia}</p>}
        { mp === 'TARJETA'
          ? <>
            <label className='buscador-label' htmlFor='Metodo De Pago'>
              Tipo de tarjeta:
            </label>
            <select
              name='MP'
              id='MP'
              className='buscador-input'
              onChange={handleChange}
              defaultValue={''}
            ><option key={0} value='' disabled>
                Selecciona el tipo de tarjeta
              </option>
              <option key={1} value='CREDITO'>
                Crédito
              </option>
              <option key={2} value='DEBITO'>
                Débito
              </option></select>
          </>
          : <></>
        }
        { mp === 'VERIFICAR'
          ? <>
            <label className='buscador-label' htmlFor='Metodo De Pago'>
            Método de pago:
            </label>
            <select
              name='MP'
              id='MP'
              className='buscador-input'
              onChange={handleChange}
              defaultValue={''}
            ><option key={0} value='' disabled>
                Selecciona el método de pago
              </option>
              <option key={1} value='CREDITO'>
                Crédito
              </option>
              <option key={2} value='DEBITO'>
                Débito
              </option>
              <option key={3} value='TRANSFERENCIA'>
                Transferencia
              </option>
              <option key={4} value='EFECTIVO'>
                Efectivo
              </option>
            </select>
          </>
          : <></>
        }
        <label className='buscador-label' htmlFor='Regimen Fiscal'>
          Regimen Fiscal:
        </label>
        <select
          name='regimen'
          id='regimen'
          className='buscador-input'
          onChange={handleChange}
          defaultValue={''}
        >
          <option key={0} value='' disabled>
            Selecciona el regimen Fiscal
          </option>
          {regimenF &&
            regimenF.map((item) => (
              <option key={item.index} value={item.num + ' - ' + item.nombre}>
                {item.num + ' - ' + item.nombre}
              </option>
            ))}
        </select>
        {errors.regimen && <p className='msg-error'>{errors.regimen}</p>}

        <label className='buscador-label' htmlFor='Uso del CFDI'>
          Uso del CFDI:
        </label>
        <select
          name='cfdi'
          id='cfdi'
          className='buscador-input'
          onChange={handleChange}
          defaultValue={''}
          disabled={form.regimen === ''}
        >
          <option key={0} value='' disabled>
            Selecciona el uso del CFDI
          </option>
          {cfdiF &&
            cfdiF.map((itemU) => (
              <option
                key={itemU.index}
                value={itemU.num + ' - ' + itemU.nombre}
              >
                {itemU.num + ' - ' + itemU.nombre}
              </option>
            ))}
        </select>
        {errors.cfdi && <p className='msg-error'>{errors.cfdi}</p>}
        <button className='buscador-button' type='submit' disabled={loading && true}>
          Facturar
        </button>
        {loading === true && <Spinner />}
        {response && mostrarAlerta(response)}
      </form>
    </>
  )
}

export default DatosCliente
