import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const SelectOptionsFamilias = ({ className, name, id, optDefault, onChange }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + 'Productos/Familias')
      .then(function (response) {
        const res = response.data
        setOptions(res.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <select name={name} id={id}
      onChange={onChange}
      className={className}
      defaultValue={''}
    >
      <option
        key={1}
        value=''
        disabled
      >
        {optDefault}
      </option>
      {options.map((option) => (
        <option
          key={option.id}
          value={option.id}
        >
          {option.name}
        </option>
      ))}
    </select>
  )
}

const SelectOptionsDistribuidores = ({ className, name, id, optDefault, onChange }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + 'Productos/Distribuidores')
      .then(function (response) {
        const res = response.data
        setOptions(res.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <select name={name} id={id}
      onChange={onChange}
      className={className}
      defaultValue={''}
    >
      <option
        key={1}
        value=''
        disabled
      >
        {optDefault}
      </option>
      {options.map((option) => (
        <option
          key={option.id}
          value={option.id + '-' + option.nombre}
        >
          {option.nombre}
        </option>
      ))}
    </select>
  )
}

SelectOptionsDistribuidores.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optDefault: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

SelectOptionsFamilias.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optDefault: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export { SelectOptionsDistribuidores, SelectOptionsFamilias }
