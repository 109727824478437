import React from 'react'
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageContainer: {
    marginRight: 10
  },
  textContainer: {
    textAlign: 'center',
    flex: 1
  },
  folioContainer: {
    marginRight: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    paddingRight: 50,
    borderWidth: 2,
    borderColor: '#000',
    borderStyle: 'solid',
    borderRadius: 5
  },
  negritasTitulo: {
    color: '#000',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  negritas: {
    color: '#000',
    fontSize: 8
  },
  folioText: {
    color: '#000',
    fontSize: 10,
    paddingTop: 5
  },
  image: {
    height: 70,
    width: 70
  },
  tableContainerDatos: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    marginBottom: 5
  },
  row: {
    height: 15,
    borderBottomWidth: 2
  },
  labelRow: {
    paddingLeft: 3,
    paddingTop: 3,
    textAlign: 'Left',
    fontSize: 7,
    fontWeight: 'bold'
  },
  table: {
    width: '100%',
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRadius: 5
  },
  rowMarca: {
    flexDirection: 'row'
  },
  header: {
    borderRightWidth: 2,
    borderBottomWidth: 2,
    flex: 1,
    padding: 3,
    backgroundColor: '#fff'
  },
  headerText: {
    fontSize: 7,
    color: 'black',
    textAlign: 'left'
  },
  cell: {
    borderRightWidth: 2,
    borderBottomWidth: 2,
    flex: 1,
    padding: 3
  },
  cellText: {
    fontSize: 7,
    color: '#000'
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 2,
    borderLeftWidth: 2,
    width: '100%',
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 5
  },
  column: {
    borderBottomWidth: 2,
    borderRightWidth: 2,
    flex: 1,
    height: 20,
    borderColor: '#000',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  result: {
    textAlign: 'left',
    fontSize: 7
  },
  label: {
    textAlign: 'left',
    fontSize: 7,
    color: '#000'
  },
  tableGarantia: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 2,
    borderLeftWidth: 2,
    width: '100%',
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 5
  },
  columnFacturacion: {
    borderBottomWidth: 2,
    borderRightWidth: 2,
    flex: 1,
    height: 20,
    borderColor: '#000',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 3,
    paddingRight: 3
  },
  columnHorarios: {
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderRightWidth: 2
  },
  labelHorariosE: {
    fontSize: 7,
    textAlign: 'center'
  },
  labelHorarios: {
    fontSize: 6,
    textAlign: 'center'
  },
  rowComentario: {
    height: 'auto',
    minHeight: 70,
    borderBottomWidth: 2
  }
})

const MostrarPreOrdenPDF = ({ orden }) => {
  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <View style={styles.container}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              src="./img/logoSAM.png"
            />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.negritasTitulo}>SERVICIO AUTORIZADO MACON</Text>
            <Text style={styles.negritas}>Canatlán 370-01 Parque Industrial Lagunero</Text>
            <Text style={styles.negritas}>Gomez Palacio, Dgo, México C.P. 35078</Text>
            <Text style={styles.negritas}>Tel. (871) 759-0101</Text>
          </View>
          <View style={styles.folioContainer}>
            <Text style={styles.folioText}>Folio</Text>
            <Text style={styles.folioText}>{orden.id}</Text>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.column}>
            <Text style={styles.label}>FECHA DE RECEPCIÓN:</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.result}></Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>FECHA DE COMPRA:</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.result}>{orden.fechacompra}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>TIPO DE SERVICIO</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.result}>{orden.tipo.toUpperCase()}</Text>
          </View>
        </View>
        <View style={styles.tableContainerDatos}>
          <View style={styles.row}>
            <Text style={styles.labelRow}>
              NOMBRE DEL CLIENTE:
              <Text style={styles.result}> {orden.nombre.toUpperCase()}</Text>
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.labelRow}>
              DOMICILIO:
              <Text style={styles.result}> {orden.direccion.toUpperCase()} COL. {orden.colonia.toUpperCase()} C.P. {orden.cp}</Text>
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.labelRow}>
              CIUDAD:
              <Text style={styles.result}> {orden.ciudad.toUpperCase()}, {orden.estado.toUpperCase()}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.rowMarca}>
            <View style={styles.cell}>
              <Text style={styles.headerText}>MODELO: </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {
                  orden.modelo
                }
              </Text>
            </View>
          </View>
          <View style={styles.rowMarca}>
            <View style={styles.cell}>
              <Text style={styles.headerText}>SERIE: </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {
                  orden.numSerie
                }
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableGarantia}>
          <View style={styles.columnFacturacion}>
            <Text style={styles.label}>FACTURA</Text>
          </View>
          <View style={styles.columnFacturacion}>
            <Text style={styles.result}></Text>
          </View>
          <View style={styles.columnFacturacion}>
            <Text style={styles.label}>FECHA DE ENTREGA:</Text>
          </View>
          <View style={styles.columnFacturacion}>
            <Text style={styles.label}></Text>
          </View>
          <View style={styles.columnFacturacion}>
            <Text style={styles.label}>HORARIOS:</Text>
          </View>
          <View style={styles.columnHorarios}>
            <Text style={styles.labelHorariosE}>LUNES A VIERNES</Text>
            <Text style={styles.labelHorarios}>9:00 AM A 1:00 PM Y 3:00 PM A 5:30 PM</Text>
          </View>
        </View>
        <View style={styles.tableContainerDatos}>
          <View style={styles.rowComentario}>
            <Text style={styles.labelRow}>
              COMENTARIOS:
              <Text style={styles.result}> {orden.comentario.toUpperCase()}</Text>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
MostrarPreOrdenPDF.propTypes = {
  orden: PropTypes.object.isRequired
}

export default MostrarPreOrdenPDF
