import React, { useEffect } from 'react'
import './Seguimiento.css'
import Spinner from '../../helpers/spinner/spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useFormSeguimiento } from '../../hooks/useFormSeguimiento'
import { validarTelefono } from '../../../modules/Functions'

const initialForm = {
  numeroOrden: '',
  origen: '',
  pagina: '',
  telefono: ''
}

function ordenEvent (event) {
  const tecla = event.key
  if (['.', 'e'].includes(tecla)) {
    event.preventDefault()
  }
}

const validationSeguimientoForm = (form) => {
  const errors = {}
  let iserror = false
  const regexNumOrden = /^\d*$/

  if (!form.numeroOrden.trim()) {
    errors.numeroOrden = "El campo 'Numero de Orden' es requerido"
    iserror = true
  } else if (!regexNumOrden.test(form.numeroOrden)) {
    errors.numeroOrden =
      "El campo 'Numero de Orden' debe de contener 4 caracteres o mas"
    iserror = true
  }

  if (!form.origen.trim()) {
    errors.origen = "El campo 'Origen' es requerido"
    iserror = true
  } else if (form.origen.trim() !== 'OTRO' && form.origen.trim() !== 'PAGINA WEB') {
    errors.origen = "El campo 'Origen' es incorrecto"
    iserror = true
  }

  if (!form.pagina.trim()) {
    errors.pagina = "El campo 'Marca' es requerido"
    iserror = true
  }
  return iserror ? errors : null
}

const validationConfirmarForm = (form) => {
  const errors = {}
  let iserror = false

  if (!form.telefono.trim()) {
    errors.telefono = "El campo 'Teléfono' es requerido"
    iserror = true
  } else if (!validarTelefono(form.telefono)) {
    errors.telefono = "El campo 'Teléfono' es incorrecto"
    iserror = true
  }

  return iserror ? errors : null
}

const Seguimiento = () => {
  const {
    form,
    errors,
    loading,
    response,
    responseBuscar,
    page,
    handleChange,
    handleSubmit,
    renderButton,
    handleClickPrev
  } = useFormSeguimiento(
    initialForm,
    validationSeguimientoForm,
    validationConfirmarForm
  )

  const formTitles = [`Seguimiento ${form.pagina}`, 'Valida tu telefono']

  useEffect(() => {
    document.title = 'Seguimiento'
  }, [])
  return (
    <>
      <div className='buscador-page'>
        <div className='buscador-wrapper'>
          <div className='buscador-wrapper-heading'>
            <button
              className='cleanB'
              disabled={page === 0}
              onClick={handleClickPrev}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <h2 className='buscador-heading'>{formTitles[page]}</h2>
          </div>
          <div className='container-form'>
            <form
              className='buscador-form'
              onSubmit={handleSubmit}
            >
              {page === 0 && (
                <div>
                  <label
                    className='buscador-label'
                    htmlFor='PrecioTotal'
                  >
                    Marca:
                  </label>

                  <select
                    name='pagina'
                    id='pagina'
                    className='buscador-input'
                    onChange={handleChange}
                    defaultValue={''}
                  >
                    <option
                      key={0}
                      value=''
                      disabled
                    >
                      Selecciona la marca
                    </option>
                    <option
                      key={1}
                      value='FRIKKO'
                    >
                      Frikko
                    </option>
                    <option
                      key={2}
                      value='HEATWAVE'
                    >
                      Heatwave
                    </option>
                  </select>
                  {errors.pagina && (
                    <p className='msg-error'>{errors.pagina}</p>
                  )}

                  <label
                    className='buscador-label'
                    htmlFor='PrecioTotal'
                  >
                    Origen:
                  </label>

                  <div style={{ marginTop: '7px' }}>
                    <label style={{ marginRight: '10px' }}>
                      <input
                        type='radio'
                        name='origen'
                        id=''
                        value='PAGINA WEB'
                        onChange={handleChange}
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                      />
                      Pagina Web
                    </label>
                    <label style={{ marginRight: '10px' }}>
                      <input
                        type='radio'
                        name='origen'
                        id='origen'
                        value='OTRO'
                        onChange={handleChange}
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                      />{' '}
                      Otro
                    </label>

                  </div>

                  {errors.origen && (
                    <p className='msg-error'>{errors.origen}</p>
                  )}

                  <label
                    className='buscador-label'
                    htmlFor='Numero de orden'
                  >
                    Numero de orden de servicio:
                  </label>
                  <input
                    type='number'
                    name='numeroOrden'
                    id='numeroOrden'
                    className='buscador-input number-input'
                    placeholder='Ej. 4564'
                    onChange={handleChange}
                    value={form.numeroOrden}
                    onKeyDown={ordenEvent}
                    step='any'
                  />
                  {errors.numeroOrden && (
                    <p className='msg-error'>{errors.numeroOrden}</p>
                  )}
                </div>
              )}
              {page === 1 && (
                <div>
                  <label
                    className='buscador-label'
                    htmlFor='Telefono'
                  >
                    Telefono:
                  </label>
                  <p style={{ marginTop: '5px', fontSize: '0.9rem' }}>
                    Para verifica tu telefono completalo con los digitos
                    faltantes los ultimos digitos de tu telefono son{' '}
                    {response &&
                      response.status === true &&
                      response.data.telefono}
                  </p>
                  <input
                    type='text'
                    name='telefono'
                    id='telefono'
                    className='buscador-input number-input'
                    placeholder='Ej. 4512536789'
                    onChange={handleChange}
                    value={form.telefono}
                    onKeyDown={ordenEvent}
                    step='any'
                  />
                  {errors.telefono && (
                    <p className='msg-error'>{errors.telefono}</p>
                  )}
                </div>
              )}
              {renderButton()}
              {response && response.status === false && (
                <p className='msg-error-final'>{response?.msg}</p>
              )}
              {responseBuscar && responseBuscar.status === false && (
                <p className='msg-error-final'>{responseBuscar?.msg}</p>
              )}
            </form>
          </div>
          {loading === true && <Spinner />}
        </div>
      </div>
    </>
  )
}

export default Seguimiento
