export const MenuItems = [
  {
    title: 'Ordenes',
    path: '/servicios',
    cName: 'dropdown-link'
  },
  {
    title: 'Seguimiento',
    path: '/seguimiento',
    cName: 'dropdown-link'
  }
]
