import React, { useEffect, useState } from 'react'
import './Nosotros.css'
import Jumbotronpages from '../../helpers/Jumbotron2/jumbotron2'

function Nosotros () {
  useEffect(() => {
    document.title = 'Sobre Nosotros' || ' '
    setWindowSize(window.innerWidth)
  }, [])

  const [windowSize, setWindowSize] = useState(0)

  const handleResize = () => {
    setWindowSize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const altoDevolver = (windowSize) => {
    if (windowSize > 992) {
      return { alto: 418, margen: 110 }
    } else if (windowSize <= 992 && windowSize > 944) {
      return { alto: 318, margen: 85 }
    } else if (windowSize <= 944 && windowSize >= 769) {
      return { alto: 318, margen: 60 }
    } else if (windowSize > 542 && windowSize <= 768) {
      return { alto: 172, margen: 60 }
    } else if (windowSize <= 542) {
      return { alto: 172, margen: 40 }
    }
  }
  return (
    <div>
      <Jumbotronpages
        image='./img/side2.jpg'
        title='Sobre Industrias Macon'
        alto={altoDevolver(windowSize).alto}
        margin={altoDevolver(windowSize).margen}
      />
      <div className='row-nosotros'>
        <div className='row-content-wrap-nosotros-info'>
          <div className='row-content-nosotros-info'>
            <div className='col-group-nosotros-info'>
              <div className='col-nosotros-info'>
                <div className='col-content-nosotros-info'>
                  <div className='module-nosotros-info'>
                    <div className='module-content-nosotros-info'>
                      <div className='heading-content-nosotros-info'>
                        <div className='heading-nosotros-info'>
                          <h3 className='heading-title-nosotros-info'>
                            <span className='title-text-nosotros-info'>
                              1976: EL INICIO
                            </span>
                          </h3>
                        </div>
                        <div className='separador-nosotros'>
                          <span className='linea-nosotros'></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-group-nosotros-info'>
                    <div className='col-nosotros-info'>
                      <div className='col-content-nosotros-info2'>
                        <div className='module-nosotros-info2'>
                          <div className='module-content-nosotros-info2'>
                            <div className='rich-text-nosotros'>
                              <p className='text-nosotros'>
                                <img
                                  decoding='async'
                                  loading='lazy'
                                  className='imageinfo'
                                  src='./img/historia-macon.png'
                                  alt=''
                                />
                                Para nuestra civilización, 1976 fue un año
                                intenso, fecundo, pleno de hallazgos culturales
                                y científicos. Se efectuaron proezas espaciales,
                                como el primer aterrizaje en Marte efectuado por
                                el Viking 1; y logros aeronáuticos: el Concorde
                                inicia sus vuelos comerciales. En 1976 se
                                inaugura en Toronto la imponente Torre CN, una
                                de las estructuras más altas del mundo en ese
                                momento.
                              </p>
                              <p className='text-nosotros'>
                                Año de naves espaciales intrépidas, aviones
                                realizando vuelos inéditos, edificaciones
                                monumentales, gestos de la audacia humana que
                                colmaron de energía y entusiasmo al orbe,
                                inspirando tanto a los deportistas en la
                                Olimpiada de Montreal, como a los emprendedores
                                y visionarios de esa época. México no podía
                                estar ajeno al espíritu creativo que imperaba
                                entonces, y menos La Laguna, región vigorosa,
                                históricamente caracterizada por sus hombres y
                                mujeres resueltos y perseverantes.
                              </p>
                              <p className='text-nosotros'>
                                Ahí, en la confluencia de los estados de
                                Coahuila y Durango, en la primavera de 1976, un
                                emprendedor de gran visión y tenacidad, decidió
                                ofrecer a las personas opciones técnicas para
                                mejorar el clima de sus entornos inmediatos. Su
                                ilusión era ofrecer productos nobles, que
                                elevaran la calidad de las condiciones de vida y
                                de trabajo, aclimatando los hogares, los centros
                                de labores y los sitios de esparcimiento.
                              </p>
                              <p className='text-nosotros'>
                                Para materializar su sueño, aplicó una enorme
                                dosis de trabajo, ahínco y perseverancia. Su
                                esfuerzo lo llevó a satisfacer no sólo su anhelo
                                visionario, sino a compartir los frutos con sus
                                colaboradores de esos primeros años. Con esta
                                visión fue fundada Industrias Macon, empresa
                                lagunera dedicada a ofrecer soluciones de
                                confort para el clima de todo tipo de espacios
                                ya sea en temporada de frío de calor.
                              </p>
                              <p className='text-nosotros'>
                                En la actualidad, una nueva generación dirige el
                                rumbo de esta compañía con el espíritu
                                emprendedor original, sosteniendo la vigente
                                ilusión de antaño y revitalizando el esfuerzo en
                                el entorno estimulante del siglo XXI. El
                                crecimiento, la consolidación y la expansión son
                                realidades de Macon, que cuenta con sucursales,
                                distribuidores y puntos de venta
                                estratégicamente distribuidos a lo largo del
                                territorio nacional.
                              </p>
                              <p className='text-nosotros'>
                                Hoy, con la misma motivación y entusiasmo con
                                los que se fundó Macon en 1976 y el rumbo bien
                                definido hacia los retos y oportunidades del
                                futuro, la dirección general mantiene un
                                liderazgo firme que busca establecer marcas
                                fuertes y productos confiables en el mercado,
                                enfocando el esfuerzo del equipo a la calidad
                                total y a un servicio de excelencia.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row-nosotros'>
        <div className='row-content-wrap-color'>
          <div className='row-content-nosotros-info'>
            <div className='col-group-nosotros'>
              <div className='col-nosotros'>
                <div className='col-content-nosotros'>
                  <div className='module-nosotros'>
                    <div className='module-content-nosotros-work'>
                      <div className='heading-content-nosotros-work'>
                        <div className='heading-nosotors-work'>
                          <h3 className='heading-title-work'>
                            <span className='tittle-text-nosotros-work'>
                              ¿Quieres trabajar con nosotros?
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='module-nosotros'>
                    <div className='module-content-nosotros-work-button'>
                      <div className='button-wrap-nosotros'>
                        <a href='/Contacto' className='button-work'>
                          <span className='button-work-text'>CONTÁCTANOS</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nosotros
