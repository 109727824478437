import swal from 'sweetalert2'
import Spinner from '../../helpers/spinner/spinner'
import React, { useFormEmail } from '../../hooks/useFormEmail'
import './CorreoEnviar.css'

const initialEmailForm = {
  email: ''
}

const validationEmailForm = (form) => {
  const errors = {}
  let iserror = false
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  if (form.email.trim()) {
    if (!regexEmail.test(form.email)) {
      errors.email = "El campo 'Correo Electrónico' es incorrecto"
      iserror = true
    }
  }

  return iserror ? errors : null
}

const mostrarAlerta = (response) => {
  swal
    .fire({
      title: 'Se han enviado los documentos de la orden',
      text: response.msg,
      icon: 'success',
      confirmButtonText: 'OK',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false
    })
    .then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/Facturacion'
      }
    })
}

const DatosEmail = (orden) => {
  const nuevaOrden = orden.orden
  const { form, errors, loading, response, handleChange, handleSubmit } =
    useFormEmail(initialEmailForm, validationEmailForm, nuevaOrden)

  return (
    <>
      <p>
        Los archivos de la factura seran enviados al correo principal, si desea
        que se envie una copia a otro correo favor de ingresarlo a
        continuación:
      </p>
      <div className='separador-correo-enviar'>
        <span className='linea-correo-enviar'></span>
      </div>
      <form onSubmit={handleSubmit}>
        <label
          className='buscador-label'
          htmlFor='Correo Electronico'
        >
          Correo Electronico:
        </label>
        <input
          type='text'
          name='email'
          id='email'
          className='buscador-input'
          placeholder='Ej. example@exemple.com'
          onChange={handleChange}
          value={form.email}
        />
        {errors.email && <p className='msg-error'>{errors.email}</p>}
        <button
          disabled={loading && true}
          className='buscador-button'
          type='submit'
        >
          Enviar Factura
        </button>
        {response && response.status === false && (
          <p className='msg-error-final'>{response?.msg}</p>
        )}
        {loading === true && <Spinner />}
        {response && response.status === true && mostrarAlerta(response)}
      </form>
    </>
  )
}

export default DatosEmail
