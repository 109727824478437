import React from 'react'

function Error404 () {
  return (
      <div>
          <h1>
              Error 404
          </h1>
      </div>
  )
}

export default Error404
