import React, { useEffect } from 'react'

function Soporte () {
  useEffect(() => {
    document.title = 'Soporte' || ' '
  }, [])
  return (
    <div>
      <h1>Soporte</h1>
    </div>
  )
}

export default Soporte
