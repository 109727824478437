import React, { useEffect } from 'react'
import './avisoPrivacidad.css'

function avisoPrivacidad () {
  useEffect(() => {
    document.title = 'Aviso de Privacidad' || ' '
  }, [])
  return (
    <div className='site-inner'>
      <main className='content'>
        <article className='aviso'>
          <header className='header'>
            <h1 className='titleAviso'>Aviso de privacidad</h1>
          </header>
          <div className='entry-content'>
            <div>
              <div className='fl-row'>
                <div className='fl-wrap'>
                  <div className='separador'></div>
                  <div className='fl-row-content'>
                    <div className='fl-col-group'>
                      <div className='fl-col'>
                        <div className='fl-col-content'>
                          <div className='fl-module'>
                            <div className='fl-module-content'>
                              <div className='fl-rich-text'>
                                <div className='mk-text-block'>
                                  <h3 className='puntoPriv'>Responsable del tratamiento de sus datos personales.</h3>
                                  <p className='descripcion'>
                                    En relación a la Ley Federal de Protección de Datos Personales en Posesión de
                                    Particulares (en lo sucesivo la Ley), METAL MECÁNICA MACON, S.A. DE C.V. e
                                    INDUSTRIAS MACON, S.A DE C.V. (en lo sucesivo MACON) con domicilio ubicado en
                                    Canatlán 370, Gómez Palacio, Durango. Col. Parque Industrial Lagunero C.P. 35078
                                    le informa que tiene la convicción de proteger y respetar los datos personales
                                    que ha proporcionado por lo que a continuación le da a conocer los términos del
                                    presente Aviso de Privacidad.
                                  </p>
                                  <h3 className='puntoPriv'>Datos Personales que se recaban.</h3>
                                  <p className='descripcion'>MACON podrá recabar y, en su caso, tratar los datos personales
                                    que a continuación se especifican, con base en la relación que con Usted, el
                                    USUARIO, exista: nombre completo; copia de identificación oficial con fotografía
                                    (pasaporte, credencial de elector, cédula profesional); edad; domicilio; copia de
                                    comprobante de domicilio (recibos de luz, teléfono o predial); nacionalidad; correo
                                    electrónico; teléfono particular, del trabajo, celular; copia del registro federal
                                    de contribuyentes; copia de la Clave Única de Registro de Probación (CURP); y/o
                                    cualquier otra documentación o información que se relacione con el objeto de los
                                    registros requeridos para la relación.</p>
                                  <p className='descripcion'>Podrán tratarse otros datos personales, sensibles y no sensibles, que no se
                                    incluyan en las listas anteriores siempre y cuando dichos datos se consideren de
                                    la misma naturaleza y no sean excesivos respecto a las finalidades para los cuales
                                    se requieren</p>

                                  <h3 className='puntoPriv'>Finalidades y/o Usos de los datos personales.</h3>
                                  <p className='descripcion'>
                                    MACON recaba, utiliza, almacena, transmite o transfiere sus datos personales, en
                                    la medida en que la Ley lo permite, para que el USUARIO esté en posibilidades
                                    de recibir cotizaciones comerciales, operaciones de compra-venta, atención de
                                    garantías y promociones comerciales, así como para el envío de información
                                    respecto de nuestros productos y servicios.
                                  </p>
                                  <h3 className='puntoPriv'>Seguridad de los datos personales.</h3>
                                  <p className='descripcion'>
                                    Con fundamento en los artículos 13, 14 y 15 de la Ley, MACON en su carácter de
                                    responsable del tratamiento de sus datos personales, no divulgará, cederá o
                                    transferirá sus datos a terceros, salvo los casos contemplados en la Ley, así
                                    mismo se compromete a guardar confidencialidad de los mismos, informándole que ha
                                    adoptado las medidas de seguridad técnicas, físicas y administrativas para
                                    procurar que sus datos estén seguros, protegidos de cualquier divulgación, daño,
                                    alteración, pérdida, así como restringidos y limitados al personal que requiera
                                    tener acceso a ellos, con el fin de llevar a cabo única y exclusivamente las
                                    finalidades antes descritas.
                                  </p>
                                  <h3 className='puntoPriv'>Derechos que le corresponden respecto a sus datos personales.</h3>
                                  <p className='descripcion'>
                                    Derivado de lo establecido en el artículo 22 de la Ley, a partir del 06 de
                                    Enero de 2012 usted podrá ejercer los derechos de Acceso, Rectificación,
                                    Cancelación y Oposición (ARCO) respecto al uso de sus datos personales, dicho
                                    ejercicio
                                    deberá realizarse mediante solicitud por escrito dirigida a
                                    METAL MECÁNICA MACON, S.A. DE C.V. en Canatlán 370, Gómez Palacio, Durango.
                                    Col. Parque Industrial Lagunero C.P. 35078, la cual deberá contener la
                                    siguiente información:
                                  </p>
                                  <ol className='ulAviso'>
                                    <li>El nombre del titular y domicilio u otro medio para comunicarle la
                                      respuesta a su solicitud;</li>
                                    <li>Los documentos que acrediten la identidad o, en su caso, la representación
                                      legal del titular;</li>
                                    <li>La descripción clara y precisa de los datos personales respecto de los que
                                      se busca ejercer alguno de los derechos antes mencionados, y</li>
                                    <li>Cualquier otro elemento o documento que facilite la localización de los
                                      datos personales.</li>
                                  </ol>
                                  <p className='descripcion'>
                                    MACON dará respuesta a su solicitud en un plazo de 20 días hábiles contados a partir de la fecha en que reciba
                                    ésta, pudiendo ampliar dicho término siempre y cuando así lo justifiquen las circunstancias, en caso de que
                                    dicha solicitud resultara procedente, la determinación adoptada se hará efectiva dentro de los quince días
                                    siguientes a la fecha en que se comunica la respuesta.
                                  </p>
                                  <p className='descripcion'>
                                    MACON se reserva el derecho a modificar en cualquier momento el contenido del
                                    presente Aviso de Privacidad, mismos que podrán serán informados a los titulares
                                    mediante correo electrónico.
                                  </p>
                                  <p className='descripcion'>
                                    Manifiesto que al haber realizado el registro respectivo o proporcionar los
                                    datos solicitados me doy por enterado del contenido de este Aviso de Privacidad y
                                    otorgo mi consentimiento, para los casos en que es necesario, para el
                                    tratamiento de mis datos personales en los términos del presente.
                                  </p>
                                  <p className='descripcion'>
                                    En términos de lo dispuesto por el artículo 8 de la Ley se entenderá que usted consiente
                                    tácitamente el tratamiento de sus datos, cuando habiéndose puesto a su disposición el
                                    presente Aviso de Privacidad, no manifieste su oposición, por lo que a partir de este
                                    momento, usted otorga su consentimiento a MACON para mantener en sus registros, tanto
                                    físicos como electrónicos, toda la información que nos haya sido facilitada por usted a
                                    través de cualquier medio.
                                  </p>
                                  <p className='descripcion'>
                                    Le informamos que, ante la negativa de respuesta a la solicitud de derechos ARCO o
                                    inconformidad con la misma, usted puede presentar ante el Instituto Federal de Acceso a
                                    la Información y Protección de Datos, la correspondiente Solicitud de Protección de
                                    Derechos en los plazos y términos fijados por la Ley y su Reglamento.
                                  </p>
                                  <h2 className='h2Aviso'>AVISO DE PRIVACIDAD DE PAGINA WEB.</h2>
                                  <h3 className='puntoPriv'>AVISO DE PRIVACIDAD SOBRE DATOS PERSONALES.</h3>
                                  <h4 className='tituloPequeño'>Responsable.</h4>
                                  <p className='descripcion'>METAL MECÁNICA MACON, S.A. DE C.V. e INDUSTRIAS MACON, S.A. DE C.V. (en lo sucesivo MACON) son responsables
                                    del tratamiento de los datos personales proporcionados a través de este sitio, del sistema 01 800 y los formatos
                                    de alta de clientes, proveedores y expedientes de personal. Domicilio: Canatlán 370, Gómez Palacio, Durango.
                                    Col. Parque Industrial Lagunero C.P. 35078.</p>
                                  <h4 className='tituloPequeño'>Datos personales proporcionados.</h4>
                                  <p className='descripcion'>Al registrarte como cliente, proveedor, visitante o beneficiario de garantías
                                    y promociones, los datos que puedes proporcionar son: Nombre completo, domicilio,
                                    número de teléfono particular, número de teléfono celular, dirección de correo
                                    electrónico, registro federal de causantes, domicilio, empresa donde labora,
                                    etc.</p>
                                  <h4 className='tituloPequeño'>Modos de recabar la información.</h4>
                                  <p className='descripcion'>Tus datos personales podrán ser recabados vía telefónica, llenando el formato
                                    de visita, formato de alta de clientes o proveedores, en línea a través del
                                    formato de contacto de nuestros sitios webs.</p>
                                  <h4 className='tituloPequeño'>¿Para qué se usa tu información personal?</h4>
                                  <p className='descripcion'>La información personal que nos proporcionas es únicamente para uso interno y
                                    específicamente para operaciones de compra venta, cotizaciones, control de
                                    visitas, atención en garantías y promociones. Los datos personales proporcionados
                                    serán conservados durante el tiempo necesario para llevar a cabo los fines para
                                    los que fueron recabados.</p>
                                  <h4 className='tituloPequeño'>Consentimiento.</h4>
                                  <p className='descripcion'>Esta declaración de Confidencialidad / Privacidad está
                                  sujeta a los términos y condiciones de todos los sitios web de MACON antes
                                  descritos, lo cual constituye un acuerdo legal entre el usuario y MACON.</p>
                                  <p className='descripcion'>Si el usuario utiliza los servicios en cualquiera de los
                                  sitios de MACON, significa que ha leído, entendido y acordado los términos
                                  antes expuestos. Si no está de acuerdo con ellos, el usuario no deberá
                                  proporcionar ninguna información personal, ni utilizar los servicios de los
                                  sitios de MACON.</p>
                                  <h4 className='tituloPequeño'>Ejercicio de Derechos ARCO y encargado.</h4>
                                  <p className='descripcion'>Tienes derecho para acceder, rectificar y cancelar tus
                                  datos personales, así como oponerte a su tratamiento o revocar el consentimiento
                                  otorgado, haciéndonos llegar tu petición por escrito a Canatlán 370, Gómez Palacio,
                                  Durango. Col. Parque Industrial Lagunero C.P. 35078. Teléfono 871 7590100</p>
                                  <h4 className='tituloPequeño'>Transferencia de Datos.</h4>
                                  <p className='descripcion'>MACON no comparte con terceros los datos personales de los
                                  usuarios de este sitio, a menos que por su naturaleza sean requeridos para
                                  otorgar el servicio referente a la relación USUARIO-MACON, lo ameriten.
                                  Únicamente podrán ser comunicados al ser requeridos por alguna autoridad de
                                  acuerdo con la normatividad aplicable.</p>
                                  <h4 className='tituloPequeño'>Limitación de uso y divulgación de la información.</h4>
                                  <p className='descripcion'>Los datos recabados, sólo serán utilizados para los fines de
                                  la relación comercial, de servicios y/o laboral que se acuerden entre las partes,
                                  y no se hará uso de ello para otros fines.</p>
                                  <h4 className='tituloPequeño'>Protección.</h4>
                                  <p className='descripcion'>MACON implementará las herramientas necesarias para la
                                  protección de la información del usuario.</p>
                                  <p className='descripcion'>Una vez recibidos los datos, haremos todo lo posible para
                                  salvaguardar su seguridad en nuestros sistemas.</p>
                                  <h4 className='tituloPequeño'>Otros Enlaces.</h4>
                                  <p className='descripcion'>Este sitio web puede contener enlaces a otros sitios en los
                                  que rigen sus propios Avisos de privacidad. MACON no es responsable de sus
                                  prácticas en el tratamiento de información personal y por tanto te recomendamos
                                  verificar sus respectivos avisos.</p>
                                  <h4 className='tituloPequeño'>Modificaciones al Aviso de Privacidad.</h4>
                                  <p className='descripcion'>MACON se reserva el derecho de efectuar en cualquier momento,
                                  modificaciones o actualizaciones al presente aviso de privacidad. Cualquier
                                  modificación será dada a conocer en nuestras páginas Web
                                  www.frikko.com, www.heatwave.com.mx, www.imacon.com,
                                  En el link de Aviso de Privacidad o se las haremos llegar al último
                                  correo electrónico que nos haya proporcionado.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </main>
    </div>
  )
}

export default avisoPrivacidad
