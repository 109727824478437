import React, { useEffect, useState } from 'react'
import { useFormFoliosServicio } from '../../hooks/useFormFoliosServicio'
import ReCAPTCHA from 'react-google-recaptcha'
import './Servicios.css'
import {
  SelectOptionsDistribuidores,
  SelectOptionsFamilias
} from '../../helpers/SelectOptionsDistributors/SelectOptionsDistribuidores'
import {
  validarEmail,
  validarEmpresa,
  validarEntreCalles,
  validarFecha,
  validarNombres,
  validarSerie,
  validarTelefono,
  validarFolioPasito
} from '../../../modules/Functions'
import Spinner from '../../helpers/spinner/spinner'
import { Modal } from '../../helpers/Modal/Modal'
import MostrarPreOrden from './MostrarPreOrden'
import Swal from 'sweetalert2'

const mostrarAlerta = (response) => {
  Swal.fire({
    title: 'No se ha podido generar la orden',
    text: response.msg,
    icon: 'error',
    confirmButtonText: 'OK',
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = '/Servicios'
    }
  })
}

const initialForm = {
  esDistribuidor: false,
  // pagina: '',
  nombre: '',
  apellidos: '',
  cp: '',
  estado: '',
  ciudad: '',
  colonia: '',
  calle: '',
  numExterior: '',
  numInterior: '',
  entreCalles: '',
  telefono: '',
  telefonoOpc: '',
  email: '',

  // Ariculos
  familia: '',
  categoria: '',
  producto: '',
  modelo: '',
  numSerie: '',
  cantidad: '',

  // Termina Articulos
  servicio: '',
  comentario: '',
  distribuidor: '',
  nombreDistribuidor: '',
  fechaCompra: '',
  imagen: '',
  folioCompra: '',
  captcha: false
}

const validateInfo = async (form) => {
  const info = {}

  if (form.servicio === 'GARANTIA') {
    info.servicio =
      'El folio que se generará solo es valido para la revisión de un equipo.' +
      ' En caso de que no proceda la garantía se deberá pagar la visita del técnico.'
  } else if (form.servicio === 'INSTALACION') {
    info.servicio =
      'El folio que se generará solo es valido para la instalación de un equipo.'
  }

  return info !== '' ? info : null
}

const validationsForm = async (form) => {
  const errors = {}
  let iserror = false
  const regexCP = /^(\d{5})$/
  const regexCalle = /^[a-zA-Z0-9\s\-.'/ñ]+$/
  const regexNumExtInt = /^[0-9A-Za-z]+(?:-[0-9A-Za-z]+)?$/
  const allowedExtensions = /(.jpg|.jpeg|.png|.gif)$/i

  const distArr = form.distribuidor.split('-')

  // if (!form.pagina.trim()) {
  //   errors.pagina = "El campo 'Marca' es requerido"
  //   iserror = true
  // }

  if (!form.nombre.trim()) {
    errors.nombre = "El campo 'Nombre(s)' es requerido"
    iserror = true
  } else if (!form.nombre.trimEnd().split(' ').every(validarNombres)) {
    errors.nombre = "El campo 'Nombre(s)' es incorrecto"
    iserror = true
  }

  if (form.esDistribuidor === false) {
    if (!form.apellidos.trim()) {
      errors.apellidos = "El campo 'Apellido(s)' es requerido"
      iserror = true
    } else if (!form.apellidos.trimEnd().split(' ').every(validarNombres)) {
      errors.apellidos = "El campo 'Apellido(s)' es incorrecto"
      iserror = true
    }

    if (!form.folioCompra.trim()) {
      errors.folioCompra = ["El campo 'Folio de compra' es requerido"]
      iserror = true
    }

    if (distArr[1] === 'Mueblería Villarreal' || distArr[1] === 'El Pasito') {
      if (!validarFolioPasito(form.folioCompra)) {
        errors.folioCompra = [
          'El campo Folio de compra para este distribuidor',
          '- Debe de contener solo números',
          '- La longitud debe de ser de 12 caracteres'
        ]
        iserror = true
      }
    } else {
      if (form.folioCompra.length > 20) {
        errors.folioCompra = [
          'El campo Folio de compra para este distribuidor',
          '- La longitud debe de ser de 20 caracteres'
        ]
        iserror = true
      }
    }
  }

  if (!form.cp.trim()) {
    errors.cp = "El campo 'Codigo Postal' es requerido"
    iserror = true
  } else if (!regexCP.test(form.cp)) {
    errors.cp = "El campo 'Codigo Postal' es incorrecto"
    iserror = true
  }

  if (!form.estado.trim()) {
    errors.estado = "El campo 'Estado' es requerido"
    iserror = true
  }

  if (!form.ciudad.trim()) {
    errors.ciudad = "El campo 'Ciudad' es requerido"
    iserror = true
  }

  if (!form.colonia.trim()) {
    errors.colonia = "El campo 'Colonia' es requerido"
    iserror = true
  }

  if (!form.calle.trim()) {
    errors.calle = "El campo 'Calle' es requerido"
    iserror = true
  } else if (!regexCalle.test(form.calle)) {
    errors.calle = "El campo 'Calle' es incorrecto"
    iserror = true
  }

  if (!form.numExterior.trim()) {
    errors.numExterior = "El campo 'Numero Exterior' es requerido"
    iserror = true
  } else if (!regexNumExtInt.test(form.numExterior.trim())) {
    errors.numExterior = "El campo 'Numero Exterior' es incorrecto"
    iserror = true
  }

  if (form.numInterior.trim()) {
    if (!regexNumExtInt.test(form.numInterior.trim())) {
      errors.numInterior = "El campo 'Correo Electrónico' es incorrecto"
      iserror = true
    }
  }

  if (form.entreCalles.trim()) {
    if (!validarEntreCalles(form.entreCalles)) {
      errors.entreCalles =
        'Favor de no usar caracteres especiales !, @, #, $, %, ^, &, *, (, ), _, +, =, [, ], {, }, |, \\, ;, :, ", ,, <, >, ?, `, ~.'
      iserror = true
    }
  }

  if (!form.telefono.trim()) {
    errors.telefono = "El campo 'Teléfono' es requerido"
    iserror = true
  } else if (!validarTelefono(form.telefono)) {
    errors.telefono = "El campo 'Teléfono' es incorrecto"
    iserror = true
  }

  if (form.telefonoOpc.trim()) {
    if (!validarTelefono(form.telefonoOpc)) {
      errors.telefonoOpc = "El campo 'Teléfono (Opcional)' es incorrecto"
      iserror = true
    }
  }

  if (!form.email.trim()) {
    errors.email = "El campo 'Correo Electrónico' es requerido"
    iserror = true
  } else if (!validarEmail(form.email.trim())) {
    errors.email = "El campo 'Correo Electrónico' es incorrecto"
    iserror = true
  }

  if (!form.familia.trim()) {
    errors.familia = "El campo 'Familia' es requerido"
    iserror = true
  }

  if (form.familia.trim() === '006' && form.servicio === 'GARANTIA') {
    if (!form.cantidad.trim()) {
      errors.cantidad = "El campo 'Cantidad' es requerido"
      iserror = true
    }
  }

  if (!form.categoria.trim()) {
    errors.categoria = "El campo 'Categoria' es requerido"
    iserror = true
  }

  if (!form.producto.trim()) {
    errors.producto = "El campo 'Producto' es requerido"
    iserror = true
  }

  if (form.producto === 'OTRO') {
    if (!form.modelo.trim()) {
      errors.modelo = "El campo 'Modelo' es requerido"
      iserror = true
    }
  }

  if (!form.modelo.trim()) {
    errors.modelo = "El campo 'Modelo' es requerido"
    iserror = true
  }

  if (!form.numSerie.trim()) {
    errors.numSerie = "El campo 'Numéro de serie' es requerido"
    iserror = true
  } else if (
    form.numSerie.trim().length <= 0 ||
    form.numSerie.trim().length > 20
  ) {
    errors.numSerie =
      "El campo 'Numéro de serie' es debe de tener un minimo de 0 y un maximo de 20 caracteres"
    iserror = true
  } else if (!validarSerie(form.numSerie)) {
    errors.numSerie = "El campo 'Numéro de serie' es incorrecto"
    iserror = true
  }

  if (!form.servicio.trim()) {
    errors.servicio = "El campo 'Servicio' es requerido"
    iserror = true
  }

  if (form.servicio === 'GARANTIA' || form.servicio === 'ACCESORIO') {
    if (!form.comentario.trim()) {
      errors.comentario = "El campo 'Comentario' es requerido"
      iserror = true
    }
  }

  if (form.familia !== '005' && form.servicio === 'INSTALACION') {
    errors.servicio = 'No hay servicio de instalación para este articulo.'
    iserror = true
  }

  if (form.familia === '005' && form.categoria === '027' && form.servicio === 'INSTALACION') {
    errors.servicio = 'No hay servicio de instalación para este articulo.'
    iserror = true
  }

  if (!form.distribuidor.trim()) {
    errors.distribuidor = "El campo 'Distribuidor' es requerido"
    iserror = true
  } else {
    const distArr = form.distribuidor.split('-')

    if (distArr[1] === 'Otro') {
      if (!form.nombreDistribuidor.trim()) {
        errors.nombreDistribuidor =
          "El campo 'Nombre del Distribuidor' es requerido"
        iserror = true
      } else if (!validarEmpresa(form.nombreDistribuidor)) {
        errors.nombreDistribuidor =
          "El campo 'Nombre del Distribuidor' es incorrecto"
        iserror = true
      }
    }
  }

  if (distArr[1] === 'Otro' && form.servicio === 'INSTALACION') {
    errors.distribuidor = "El campo 'Distribuidor' no puede ser 'OTRO'"
    iserror = true
  }

  if (!form.fechaCompra.trim()) {
    errors.fechaCompra = "El campo 'Fecha de compra' es requerido"
    iserror = true
  } else {
    let valor = ''
    if (form.servicio === 'INSTALACION') {
      valor = await validarFecha(
        form.fechaCompra,
        'INSTALACION',
        form.distribuidor
      )
    } else if (form.servicio === 'GARANTIA') {
      valor = await validarFecha(
        form.fechaCompra,
        'GARANTIA',
        form.distribuidor
      )
    } else if (form.servicio === 'ACCESORIO') {
      valor = await validarFecha(
        form.fechaCompra,
        'ACCESORIO',
        form.distribuidor
      )
    }

    if (typeof valor === 'string') {
      errors.fechaCompra = valor
      iserror = true
    }
  }

  if (!form.imagen.trim()) {
    errors.imagen = "El campo 'Imagen' es requerido"
    iserror = true
  } else if (!allowedExtensions.exec(form.imagen)) {
    errors.imagen = "El formato del archivo 'Imagen' es incorrecto"
    iserror = true
  }

  if (!form.captcha) {
    errors.captcha = 'Por favor valida el Captcha'
    iserror = true
  }
  return iserror ? errors : null
}

function Servicios () {
  const [modalOpen, setModalOpen] = useState(false)
  const {
    form,
    errors,
    info,
    loading,
    response,
    col,
    categorias,
    productos,
    captchaChange,
    handleChange,
    handleSubmit
  } = useFormFoliosServicio(
    initialForm,
    handleSuccess,
    validationsForm,
    validateInfo
  )

  function handleSuccess () {
    setModalOpen(true)
  }
  useEffect(() => {
    document.title = 'Servicios' || ' '
  }, [])
  return (
    <div className='folio-page'>
      <div className='folio-wrapper'>
        <div className='folio-wrapper-heading'>
          <h2 className='folio-heading'>
            Solicitud de servicio o garantía {form.pagina}
          </h2>
        </div>
        <div className='container-folio-form'>
          <form
            className='folio-form'
            onSubmit={handleSubmit}
            encType='multipart/form-data'
          >
            {/* <label
              className='folio-label'
              htmlFor='Marca del articulo'
            >
              Marca del articulo:
            </label>
            <select
              name='pagina'
              id='pagina'
              className='folio-input'
              onChange={handleChange}
              defaultValue={''}
            >
              <option
                key={1}
                value=''
                disabled
              >
                Selecciona la marca del articulo
              </option>
              <option
                key={2}
                value='FRIKKO'
              >
                Frikko
              </option>
              <option
                key={3}
                value='HEATWAVE'
              >
                Heatwave
              </option>
              <option
                key={4}
                value='AIRON'
              >
                Airon
              </option>
              <option
                key={5}
                value='MAXXICOOL'
              >
                Maxxicool
              </option>
              <option
                key={6}
                value='ECONOCOOL'
              >
                Econocool
              </option>
            </select>
            {errors.pagina && errors.pagina !== '' && (
              <p className='msg-error'>{errors.pagina}</p>
            )} */}
            <section className='distributor'>
              <input
                type='checkbox'
                name='esDist'
                id='esDist'
                className='folio-input-check'
                onChange={handleChange}
              />
              <label className='folio-label-check' htmlFor='esDist'>¿Eres Distribuidor?</label>
            </section>
            <section>
              <h1 className='title-section-folios'>Nombre</h1>
              {
                form.esDistribuidor === true
                  ? <>
                    <input
                      type='text'
                      name='nombre'
                      id='nombre'
                      className='folio-input'
                      onChange={handleChange}
                      placeholder='Nombre(s)'
                      value={form.nombre}
                      maxLength={50}
                      disabled={form.esDistribuidor}
                    />
                    {errors.nombre && errors.nombre !== '' && (
                      <p className='msg-error'>{errors.nombre}</p>
                    )}
                  </>
                  : <div className='folio-row'>
                    <div className='folio-column'>
                      <input
                        type='text'
                        name='nombre'
                        id='nombre'
                        className='folio-input'
                        onChange={handleChange}
                        placeholder='Nombre(s)'
                        value={form.nombre}
                        maxLength={50}
                        disabled={form.esDistribuidor}
                      />
                      {errors.nombre && errors.nombre !== '' && (
                        <p className='msg-error'>{errors.nombre}</p>
                      )}
                    </div>
                    <div className='folio-column'>
                      <input
                        type='text'
                        name='apellidos'
                        id='apellidos'
                        className='folio-input'
                        onChange={handleChange}
                        placeholder='Apellido(s)'
                        value={form.apellidos}
                        maxLength={50}
                        disabled={form.esDistribuidor}
                      />
                      {errors.apellidos && errors.apellidos !== '' && (
                        <p className='msg-error'>{errors.apellidos}</p>
                      )}
                    </div>
                  </div>
              }
            </section>
            <section>
              <h1 className='title-section-folios'>Dirección</h1>
              <div className='folio-row'>
                <div className='folio-column'>
                  <input
                    type='text'
                    name='cp'
                    id='cp'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Código Postal'
                    value={form.cp}
                    maxLength={5}
                  />
                  {errors.cp && errors.cp !== '' && (
                    <p className='msg-error'>{errors.cp}</p>
                  )}
                </div>
                <div className='folio-column'>
                  <input
                    type='text'
                    name='estado'
                    id='estado'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Estado'
                    disabled
                    value={form.estado}
                  />
                  {errors.estado && errors.estado !== '' && (
                    <p className='msg-error'>{errors.estado}</p>
                  )}
                </div>
                <div className='folio-row'>
                  <div className='folio-column'>
                    <input
                      type='text'
                      name='ciudad'
                      id='ciudad'
                      className='folio-input'
                      placeholder='Ciudad'
                      disabled
                      value={form.ciudad}
                    />
                    {errors.ciudad && errors.ciudad !== '' && (
                      <p className='msg-error'>{errors.ciudad}</p>
                    )}
                  </div>
                  <div className='folio-column'>
                    <select
                      name='colonia'
                      id='colonia'
                      className='folio-input'
                      onChange={handleChange}
                      defaultValue={''}
                    >
                      <option
                        key={1}
                        value={''}
                        disabled
                      >
                        Selecciona la colonia
                      </option>
                      {col &&
                        col.map((item, index) => (
                          <option
                            key={index}
                            value={item}
                          >
                            {item}
                          </option>
                        ))}
                    </select>
                    {errors.colonia && errors.colonia !== '' && (
                      <p className='msg-error'>{errors.colonia}</p>
                    )}
                  </div>
                </div>
                <div className='folio-row'>
                  <div className='folio-column'>
                    <input
                      type='text'
                      name='calle'
                      id='calle'
                      className='folio-input'
                      onChange={handleChange}
                      placeholder='Calle'
                      value={form.calle}
                      maxLength={50}
                    />
                    {errors.calle && errors.calle !== '' && (
                      <p className='msg-error'>{errors.calle}</p>
                    )}
                  </div>
                  <div className='folio-column'>
                    <input
                      type='text'
                      name='numExterior'
                      id='numExterior'
                      className='folio-input'
                      onChange={handleChange}
                      placeholder='Numero Exterior'
                      value={form.numExterior}
                      maxLength={10}
                    />
                    {errors.numExterior && errors.numExterior !== '' && (
                      <p className='msg-error'>{errors.numExterior}</p>
                    )}
                  </div>
                </div>
                <div className='folio-row'>
                  <div className='folio-column'>
                    <input
                      type='text'
                      name='numInterior'
                      id='numInterior'
                      className='folio-input'
                      onChange={handleChange}
                      placeholder='Numero Interior'
                      value={form.numInterior}
                      maxLength={10}
                    />
                    {errors.numInterior && errors.numInterior !== '' && (
                      <p className='msg-error'>{errors.numInterior}</p>
                    )}
                  </div>
                  <div className='folio-column'>
                    <input
                      type='text'
                      name='entreCalles'
                      id='entreCalles'
                      className='folio-input'
                      onChange={handleChange}
                      placeholder='Entre calles'
                      value={form.entreCalles}
                      maxLength={50}
                    />
                    {errors.entreCalles && errors.entreCalles !== '' && (
                      <p className='msg-error'>{errors.entreCalles}</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <h1 className='title-section-folios'>Teléfono</h1>
              <div className='folio-row'>
                <div className='folio-column'>
                  <input
                    type='text'
                    name='telefono'
                    id='telefono'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Teléfono'
                    value={form.telefono}
                    maxLength={10}
                  />
                  {errors.telefono && errors.telefono !== '' && (
                    <p className='msg-error'>{errors.telefono}</p>
                  )}
                </div>
                <div className='folio-column'>
                  <input
                    type='text'
                    name='telefonoOpc'
                    id='telefonoOpc'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Telefono (Opcional)'
                    value={form.telefonoOpc}
                    maxLength={10}
                  />
                  {errors.telefonoOpc && errors.telefonoOpc !== '' && (
                    <p className='msg-error'>{errors.telefonoOpc}</p>
                  )}
                </div>
              </div>
            </section>
            <section>
              <h1 className='title-section-folios'>Correo electrónico</h1>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <input
                    type='text'
                    name='email'
                    id='email'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Correo electrónico'
                    value={form.email}
                  />
                  {errors.email && errors.email !== '' && (
                    <p className='msg-error'>{errors.email}</p>
                  )}
                </div>
              </div>
            </section>
            <section>
              <h1 className='title-section-folios'>Artículo</h1>
              <div className='folio-row'>
                <div className='folio-column'>
                  <SelectOptionsFamilias
                    className='folio-input'
                    name='familia'
                    id='familia'
                    optDefault='Selecciona la Familia'
                    onChange={handleChange}
                  ></SelectOptionsFamilias>
                  {errors.familia && errors.familia !== '' && (
                    <p className='msg-error'>{errors.familia}</p>
                  )}
                </div>
                <div className='folio-column'>
                  <select
                    name='categoria'
                    id='categoria'
                    className='folio-input'
                    onChange={handleChange}
                    value={form.categoria}
                  >
                    <option
                      key={1}
                      value=''
                      disabled
                    >
                      Selecciona la categoria
                    </option>
                    {categorias &&
                      categorias.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.id}`}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {errors.categoria && errors.categoria !== '' && (
                    <p className='msg-error'>{errors.categoria}</p>
                  )}
                </div>
              </div>
              <div className='folio-row'>
                {form.categoria !== ''
                  ? (
                    <div className='folio-column'>
                      <select
                        name='producto'
                        id='producto'
                        className='folio-input'
                        onChange={handleChange}
                        value={form.producto}
                      >
                        <option
                          key={1}
                          value=''
                          disabled
                        >
                          Selecciona el Producto
                        </option>
                        {productos &&
                          productos.map((item, index) => (
                            <option
                              key={index}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))
                        }
                        {
                          productos &&
                          <option
                            key={productos.length + 1}
                            value='OTRO'
                          >
                            Otro
                          </option>
                        }
                      </select>
                      {errors.producto && errors.producto !== '' && (
                        <p className='msg-error'>{errors.producto}</p>
                      )}
                    </div>
                    )
                  : (
                    <></>
                    )}
                {
                  form.producto === 'OTRO'
                    ? (
                      <div className='folio-column'>
                        <input
                          type='text'
                          name='modelo'
                          id='modelo'
                          className='folio-input'
                          onChange={handleChange}
                          placeholder='Modelo'
                          value={form.modelo}
                          maxLength={20}
                        />
                        {errors.modelo && errors.modelo !== '' && (
                          <p className='msg-error'>{errors.modelo}</p>
                        )}
                      </div>
                      )
                    : (<div className='folio-column'>
                      <input
                        type='text'
                        name='numSerie'
                        id='numSerie'
                        className='folio-input'
                        onChange={handleChange}
                        placeholder='Numero de serie'
                        value={form.numSerie}
                        maxLength={20}
                      />
                      {errors.numSerie && errors.numSerie !== '' && (
                        <p className='msg-error'>{errors.numSerie}</p>
                      )}
                    </div>)
                }
              </div>
              <div className='folio-row'>
                {form.producto === 'OTRO'
                  ? (
                    <div className='folio-column'>
                      <input
                        type='text'
                        name='numSerie'
                        id='numSerie'
                        className='folio-input'
                        onChange={handleChange}
                        placeholder='Numero de serie'
                        value={form.numSerie}
                        maxLength={20}
                      />
                      {errors.numSerie && errors.numSerie !== '' && (
                        <p className='msg-error'>{errors.numSerie}</p>
                      )}
                    </div>
                    )
                  : <></>}
                {form.esDistribuidor === true
                  ? (
                      form.categoria !== '' && form.familia === '006' &&
                      form.servicio === 'GARANTIA' && form.servicio !== ''
                        ? (<div className='folio-column'>
                        <input
                          type='number'
                          name='cantidad'
                          id='cantidad'
                          className='folio-input'
                          onChange={handleChange}
                          placeholder='Cantidad'
                          value={form.catidad}
                          max={10}
                          min={0}
                        />
                        {errors.cantidad && errors.cantidad !== '' && (
                          <p className='msg-error'>{errors.cantidad}</p>
                        )}
                      </div>
                          )
                        : (
                        <></>
                          )
                    )
                  : (
                    <></>
                    )
                }
              </div>
            </section>
            <section>
              <h1 className='title-section-folios'>Tipo de servicio</h1>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <select
                    type='text'
                    name='servicio'
                    id='servicio'
                    className='folio-input'
                    onChange={handleChange}
                    placeholder='Servicio'
                    defaultValue=''
                  >
                    <option
                      key={1}
                      value=''
                      disabled
                    >
                      Selecciona el servicio
                    </option>
                    <option
                      key={2}
                      value='INSTALACION'
                    >
                      Instalación
                    </option>
                    <option
                      key={3}
                      value='GARANTIA'
                    >
                      Garantía
                    </option>
                    <option
                      key={4}
                      value='ACCESORIO'
                    >
                      Falta de accesorios
                    </option>
                  </select>
                  {errors.servicio && errors.servicio !== '' && (
                    <p className='msg-error'>{errors.servicio}</p>
                  )}
                  {info.servicio && info.servicio !== '' && (
                    <p className='msg-info'>{info.servicio}</p>
                  )}
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <textarea
                    name='comentario'
                    id='comentario'
                    className='folio-input text-area'
                    onChange={handleChange}
                    placeholder='Comentario'
                  ></textarea>
                  {errors.comentario && errors.comentario !== '' && (
                    <p className='msg-error'>{errors.comentario}</p>
                  )}
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <SelectOptionsDistribuidores
                    className='folio-input'
                    name='distribuidor'
                    id='distribuidor'
                    optDefault='Selecciona el Distribuidor'
                    onChange={handleChange}
                  ></SelectOptionsDistribuidores>
                  {errors.distribuidor && errors.distribuidor !== '' && (
                    <p className='msg-error'>{errors.distribuidor}</p>
                  )}
                </div>
              </div>
              <div className='folio-row'>
                {form.distribuidor === ''
                  ? (
                    <></>
                    )
                  : (
                      form.distribuidor.includes('Otro') && (
                      <div className='folio-column-full'>
                        <input
                          type='text'
                          name='nombreDistribuidor'
                          id='nombreDistribuidor'
                          className='folio-input'
                          onChange={handleChange}
                          placeholder='Nombre del Distribuidor'
                          value={form.nombreDistribuidor}
                          maxLength={50}
                        />
                        {errors.nombreDistribuidor &&
                          errors.nombreDistribuidor !== '' && (
                            <p className='msg-error'>
                              {errors.nombreDistribuidor}
                            </p>
                        )}
                      </div>
                      )
                    )}
              </div>
              <h1 className='title-section-folios'>Fecha de compra</h1>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <input
                    type='date'
                    name='fechaCompra'
                    id='fechaCompra'
                    className='folio-input input-date'
                    onChange={handleChange}
                    value={form.fechaCompra}
                  />
                  {errors.fechaCompra && errors.fechaCompra !== '' && (
                    <p className='msg-error'>{errors.fechaCompra}</p>
                  )}
                </div>
              </div>
            </section>
            <section>
              <h1 className='title-section-folios'>Comprobante de compra</h1>
              <div className='folio-row'>
                <div className='folio-column-full'>
                  <input
                    type='file'
                    name='imagen'
                    id='imagen'
                    className='folio-input input-date'
                    onChange={handleChange}
                    value={form.imagen}
                  />
                  {errors.imagen && errors.imagen !== '' && (
                    <p className='msg-error'>{errors.imagen}</p>
                  )}
                </div>
              </div>
            </section>
            {
              form.esDistribuidor === false
                ? (
                  <section>
                    <h1 className='title-section-folios'>Folio de compra</h1>
                    <div className='folio-row'>
                      <div className='folio-column-full'>
                        <input
                          type='text'
                          name='folioCompra'
                          id='folioCompra'
                          className='folio-input'
                          onChange={handleChange}
                          value={form.folioCompra}
                          placeholder='Folio de compra'
                        />
                        {errors.folioCompra && (
                          <p className='msg-error'>{
                            errors.folioCompra.map((line, index) => (
                              <p key={index}>{line}</p>
                            ))}
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                  )
                : <></>
            }
            <section>
              <h1 className='title-section-folios'>Valida el Captcha</h1>
              <ReCAPTCHA
                name='captcha'
                sitekey='6LeX4K0gAAAAADk_Kj6wiwPFKIpAywdjX-TW3Ygg'
                onChange={captchaChange}
              />
              {errors.captcha && errors.captcha !== '' && (
                <p className='msg-error'>{errors.captcha}</p>
              )}
            </section>
            <section>
              <div className='folio-row folio-row-center'>
                <div className='folio-column-full folio-column-center'>
                  <button
                    className='buscador-button button-center'
                    type='submit'
                    disabled={loading && true}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </section>
            {loading === true && <Spinner />}
          </form>
          {response &&
            (response.status === true
              ? (
                <div>
                  <Modal
                    ancho={800}
                    header={'Orden creada correctamente ' + response.data.id}
                    isOpen={modalOpen}
                    closeModal={() => {
                      setModalOpen(false)
                      window.location.reload()
                    }}
                  >
                    <MostrarPreOrden orden={response.data}></MostrarPreOrden>
                  </Modal>
                </div>
                )
              : (
                  mostrarAlerta(response.msg, '/')
                ))}
        </div>
      </div>
    </div>
  )
}

export default Servicios
