import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const useFormSeguimiento = (
  initialForm,
  validateForm,
  validationConfirmarForm
) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [responseBuscar, setResponseBuscar] = useState(null)
  const [page, setPage] = useState(0)
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'telefono') {
      form.telefono = value.replace(/\D/g, '')
      setTimeout(() => { setForm(form) }, 0)
    }

    setForm({
      ...form,
      [name]: value
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const errt = validateForm(form)
    const err = validationConfirmarForm(form)

    if (err === null && errt == null) {
      setLoading(true)

      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL + 'Servicios/mostrarSeguimiento',
          {
            marca: form.pagina,
            telefono: form.telefono,
            folio: form.numeroOrden,
            origen: form.origen
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          const { data } = response
          if (data.status === true) {
            setResponseBuscar(data)
            return navigate('/mostrar-seguimiento', { state: data.data })
          } else {
            setResponseBuscar(data)
            setTimeout(() => {
              setResponseBuscar({})
            }, 3000)
          }

          setTimeout(() => setLoading(false), 0)
        })
        .catch((err) => {
          console.log(err.response.data)
        })
    } else {
      setErrors(err)
      setTimeout(() => setErrors({}), 3000)
    }
  }

  const handleClickPrev = () => {
    form.pagina = ''
    setForm(form)
    setPage(page - 1)
  }

  const handleClickNext = async (event) => {
    event.preventDefault()

    const err = validateForm(form)
    if (err === null) {
      setLoading(true)
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Servicios/Seguimiento', {
          folio: form.numeroOrden,
          origen: form.origen
        })
        .then((response) => {
          const { data } = response
          if (data.status === true) {
            setPage(page + 1)
            setToken(data.data.token)
            setResponse(data)
            setTimeout(() => setLoading(false), 0)
          } else {
            setResponse(data)
            setTimeout(() => setResponse(false), 3000)
            setTimeout(() => setLoading(false), 0)
          }
        })
        .catch(() => {
          setTimeout(() => setLoading(false), 0)
          setResponse({
            status: false,
            data: {},
            msg: 'Favor de intentarlo mas tarde'
          })
          setTimeout(() => setResponse(false), 3000)
        })
    } else {
      setErrors(err)
      setTimeout(() => setErrors({}), 3000)
    }
  }

  const renderButton = () => {
    if (page === 1) {
      return (
        <button
          type='submit'
          className='buscador-button'
        >
          Mostrar Orden
        </button>
      )
    } else {
      return (
        <button
          type='button'
          onClick={handleClickNext}
          className='buscador-button'
        >
          Siguiente
        </button>
      )
    }
  }

  return {
    form,
    errors,
    loading,
    response,
    responseBuscar,
    page,
    handleChange,
    handleSubmit,
    renderButton,
    handleClickPrev
  }
}
