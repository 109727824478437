import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import App from './components/pages/AppG/App'

const navbar = ReactDOMClient.createRoot(document.getElementById('content'))
navbar.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
