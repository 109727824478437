import React from 'react'
import Navbar from '../../helpers/Navbar/Navbar'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Inicio from '../Inicio/Inicio'
import Nosotros from '../Nosotros/Nosotros'
import Marcas from '../Marcas/Marcas'
import Contacto from '../Contacto/Contacto'
import Servicios from '../Servicios/Servicios'
import BuscarOrden from '../BuscarOrden/BuscarOrden'
import Soporte from '../Soporte/Soporte'
import MostrarSeguimiento from '../Seguimiento/MostrarAvancePreOrden'
import MostrarOrden from '../MostrarOrden/MostrarOrden'
import AvisoPrivacidad from '../AvisoPrivacidad/avisoPrivacidad'
import Seguimiento from '../Seguimiento/Seguimiento'
import Error404 from '../Error 404/Error404'

function App () {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Inicio />}></Route>
        <Route path='/sobre-nosotros' element={<Nosotros />}></Route>
        <Route path='/marcas' element={<Marcas />}></Route>
        <Route path='/contacto' element={<Contacto />}></Route>
        <Route path='/servicios' element={<Servicios />}></Route>
        <Route path='/facturacion' element={<BuscarOrden />}></Route>
        <Route path='/mostrar-orden' element={<MostrarOrden />} />
        <Route path='/soporte' element={<Soporte />} />
        <Route path='/aviso-de-privacidad' element={<AvisoPrivacidad />}></Route>
        <Route path='/seguimiento' element={<Seguimiento />}></Route>
        <Route path='/mostrar-seguimiento' element={<MostrarSeguimiento />} />
        <Route path='*' element={<Error404 />}></Route>
      </Routes>
    </Router>
  )
}

export default App
